import React from "react";

type QuizQuestionCounterProps = {
  minQuestionCount: number;
  maxQuestionCount: number;
  curQuestionCount: number;
};

const QuizQuestionCounter = (props: QuizQuestionCounterProps) => {
  const { minQuestionCount, maxQuestionCount, curQuestionCount } = props;

  return (
    <div className="bg-p-white">
      <div className="font-semibold text-primary mb-1">
        selected: {curQuestionCount} / {maxQuestionCount}
      </div>
      <div className="text-xs italic">
        pick between {minQuestionCount} and {maxQuestionCount} questions
      </div>
    </div>
  );
};

export default QuizQuestionCounter;
