import React, { useCallback, useState } from "react";
import PConfirmationDialog, {
  PConfirmationDialogProps,
} from "./PConfirmationDialog";

// eslint-disable-next-line import/prefer-default-export
export const useAsyncConfirmationDialog = () => {
  const [show, setShow] = useState<boolean>(false);

  const [props, setProps] = useState<PConfirmationDialogProps>({});

  const [promiseResolve, setPromiseResolve] = useState<
    ((confirmed: boolean) => void) | null
  >(null);

  const confirm = useCallback(async (innerProps: PConfirmationDialogProps) => {
    setShow(true);
    setProps(innerProps);

    return new Promise<boolean>((resolve) => {
      // NOTE: If you pass a callback to setState, React will call that callback,
      // so that's why this is wrapped in a pointless arrow function.
      setPromiseResolve(() => resolve);
    });
  }, []);

  return {
    dialog: show ? (
      <PConfirmationDialog
        onConfirm={() => {
          setShow(false);
          promiseResolve?.(true);
        }}
        onCancel={() => {
          setShow(false);
          promiseResolve?.(false);
        }}
        {...props}
      />
    ) : null,
    confirm,
  };
};
