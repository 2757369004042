import React, { ReactNode, useRef, useState } from "react";
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { components } from "../../../../api/spec";
import PSkeleton from "../../../ui/PSkeleton";
import PBaseDialog from "../../../ui/dialogs/PBaseDialog";
import PInput from "../../../ui/input/PInput";
import PToggle from "../../../ui/input/PToggle";
import { useBusyWatcher, useUniqueId } from "../../../../util/hooks";
import PButton from "../../../ui/buttons/PButton";
import PTooltip from "../../../ui/PTooltip";
import PErrors from "../../../ui/input/PErrors";

type ReportSharingDialogScreenProps = {
  sharingStatus:
    | components["schemas"]["GetReportSharingStatusResponseSerializerWrapper"]
    | null;
  onToggleClicked: (enableSharing: boolean) => void;
  errors: string[];
};

type innerProps = ReportSharingDialogScreenProps & {
  onClose?: (() => void) | null;
};

const ReportSharingDialogScreenComponent = (props: innerProps) => {
  const { sharingStatus, onToggleClicked, onClose, errors } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const tooltipId = useUniqueId();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [busy, _] = useBusyWatcher();

  const close = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const copyToClipboard = async () => {
    if (inputRef.current) {
      inputRef.current.select();
      await navigator.clipboard.writeText(inputRef.current.value);
      toast.success("Copied to clipboard");
    }
  };

  const getBody = (): ReactNode => {
    if (errors.length > 0) {
      return <PErrors errors={errors} />;
    }
    if (sharingStatus === null) {
      return (
        <div className="h-32 overflow-hidden rounded-md">
          <PSkeleton />
        </div>
      );
    }
    return (
      <>
        <div className="flex flex-col gap-3">
          <PInput
            type="url"
            value={sharingStatus.content.view_url}
            disabled={!sharingStatus.content.is_shared || busy}
            className={
              sharingStatus.content.is_shared ? undefined : "select-none"
            }
            data-tooltip-id={tooltipId}
            data-tooltip-content={
              !sharingStatus.content.is_shared
                ? "Enable link sharing to copy this URL"
                : null
            }
            label="Shareable URL"
            ref={inputRef}
            onClick={
              sharingStatus.content.is_shared ? copyToClipboard : undefined
            }
            readOnly
          />
          <PToggle
            onToggled={onToggleClicked}
            disabled={busy}
            toggled={sharingStatus.content.is_shared}
            leftLabel="Enable link sharing?"
          />
          <div className="text-left text-sm">
            When link sharing is enabled, anyone with the link above can view
            this report.
          </div>
        </div>
        <PTooltip place="bottom" id={tooltipId} />
      </>
    );
  };

  return (
    <PBaseDialog
      title="Share Report"
      show={isOpen}
      onClose={close}
      titleIcon={<DocumentChartBarIcon className="text-primary" />}
    >
      <div className="sm:w-screen sm:max-w-md">{getBody()}</div>
      <div className="flex justify-end">
        <PButton kind="primary" onClick={close}>
          Close
        </PButton>
      </div>
    </PBaseDialog>
  );
};

ReportSharingDialogScreenComponent.defaultProps = {
  onClose: null,
};

export default ReportSharingDialogScreenComponent;
