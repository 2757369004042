import React, { useCallback, useState } from "react";
import { Recipient } from "../RecipientEntryRow";

import AddRecipientDialog from "./AddRecipientDialog";

// eslint-disable-next-line import/prefer-default-export
export const useAddRecipientDialog = () => {
  const [show, setShow] = useState<boolean>(false);

  const [promiseResolve, setPromiseResolve] = useState<
    ((newRecipient: Recipient | null) => void) | null
  >(null);

  const promptForRecipient = useCallback(async () => {
    setShow(true);

    return new Promise<Recipient | null>((resolve) => {
      // NOTE: If you pass a callback to setState, React will call that callback,
      // so that's why this is wrapped in a pointless arrow function.
      setPromiseResolve(() => resolve);
    });
  }, []);

  return {
    dialog: show ? (
      <AddRecipientDialog
        onAddClicked={(newRecipient: Recipient) => {
          setShow(false);
          promiseResolve?.(newRecipient);
        }}
        onCancel={() => {
          setShow(false);
          promiseResolve?.(null);
        }}
      />
    ) : null,
    promptForRecipient,
  };
};
