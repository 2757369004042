import React, { useEffect, useState } from "react";
import { PScreenShellProps } from "../../../ui/layout/common";
import { components, operations } from "../../../../api/spec";
import { useBusyWatcher } from "../../../../util/hooks";
import { listLLMInvocations } from "../../../../api/aiproxy/prompt";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";
import { parseQueryString, updateQueryString } from "../../../../util/query";
import PSidebarScreen from "../../../ui/layout/PSidebarScreen";
import ListInvocationsScreen from "./ListInvocationsScreen";
import { PBreadcrumbsProps } from "../../../ui/PBreadcrumbs";

type ListInvocationsPageProps = PScreenShellProps;

const ListInvocationsPageComponent = (props: ListInvocationsPageProps) => {
  const { navbar } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [listQuery, setListQuery] = useState<
    operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
  >({});
  const [invocations, setInvocations] = useState<
    components["schemas"]["ListLLMInvocationsResponseSerializerWrapper"] | null
  >(null);
  const [busy, busyWatcher] = useBusyWatcher();

  const fetchInvocations = async (
    query: operations["aiproxy_api_prompts_list_retrieve"]["parameters"]["query"],
  ) => {
    const [newInvocations, newErrors] = await listLLMInvocations(query, [
      busyWatcher,
    ]);
    if (newErrors !== null) {
      setErrors([newErrors!.message!]);
      return;
    }
    setListQuery(query);
    setInvocations(newInvocations!);
  };

  const offset = listQuery?.offset ?? 0;
  const pageSize = listQuery?.limit ?? DEFAULT_PAGE_SIZE;

  const onSearchTermUpdated = async (searchTerm: string | null) => {
    let existingSearchTerm = null;
    if (listQuery !== undefined && listQuery.search !== undefined) {
      existingSearchTerm = listQuery.search;
    }
    if (existingSearchTerm === searchTerm) {
      return;
    }
    const query = { ...listQuery, offset: 0, search: searchTerm ?? undefined };
    await fetchInvocations(query);
  };

  const onBackButtonClicked = async () => {
    const newOffset = Math.max(0, offset - pageSize);
    const query = { ...listQuery, offset: newOffset };
    await fetchInvocations(query);
  };

  const onNextButtonClicked = async () => {
    const newOffset = offset + pageSize;
    const query = { ...listQuery, offset: newOffset };
    await fetchInvocations(query);
  };

  const onAddFilterClicked = async (
    field: keyof NonNullable<
      operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
    >,
    value: any,
  ) => {
    if (listQuery) {
      const existingValue = listQuery[field];
      if (existingValue === value) {
        return;
      }
    }
    const query = { ...listQuery, [field]: value };
    await fetchInvocations(query);
  };

  const onClearFilterClicked = async (
    field: keyof NonNullable<
      operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
    >,
  ) => {
    const query = { ...listQuery, [field]: undefined };
    await fetchInvocations(query);
  };

  const getCurrentQueryContents = () => {
    const template: operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"] =
      {
        limit: -1,
        llm_model: "",
        llm_prompt: "",
        llm_provider: "",
        offset: -1,
        search: "",
      };
    return parseQueryString(template);
  };

  useEffect(() => {
    fetchInvocations(getCurrentQueryContents());
  }, []);

  useEffect(() => {
    updateQueryString(listQuery ?? {});
  }, [listQuery]);

  const getBreadrumbs = (): PBreadcrumbsProps => ({
    homeUrl: "/aiproxy/",
    entries: [{ name: "Invocations" }],
  });

  return (
    <PSidebarScreen navbar={navbar} breadcrumbs={getBreadrumbs()}>
      <ListInvocationsScreen
        errors={errors}
        disabled={busy}
        invocations={invocations}
        onBackButtonClicked={onBackButtonClicked}
        onNextButtonClicked={onNextButtonClicked}
        onAddFilterClicked={onAddFilterClicked}
        onClearFilterClicked={onClearFilterClicked}
        query={listQuery}
        onSearchUpdated={onSearchTermUpdated}
      />
    </PSidebarScreen>
  );
};

export default ListInvocationsPageComponent;
