import React, { useState } from "react";
import RecipientEntryRow, { Recipient } from "../RecipientEntryRow";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PPlusButton from "../../../ui/buttons/PPlusButton";

type RecipientEntryScreenProps = {
  maxRecipients: number;
  onNextClicked: (recipients: Recipient[]) => void;
  onBackClicked: () => void;
  onSkipClicked: () => void;
};

const RecipientEntryScreenComponent = (props: RecipientEntryScreenProps) => {
  const {
    maxRecipients,
    onNextClicked: onNextClickedInput,
    onBackClicked,
    onSkipClicked,
  } = props;

  const [numRecipients, setNumRecipients] = useState<number>(1);
  const [recipientKeys, setRecipientKeys] = useState<string[]>([
    crypto.randomUUID(),
  ]);
  const [recipients, setRecipients] = useState<(Recipient | null)[]>([null]);
  const [busy, _] = useBusyWatcher();

  const onRecipientUpdated = (index: number, recipient: Recipient | null) => {
    const newRecipients = [...recipients];
    newRecipients[index] = recipient;
    setRecipients(newRecipients);
  };

  const onAddRecipientClicked = () => {
    if (numRecipients >= maxRecipients) {
      return;
    }
    setNumRecipients(numRecipients + 1);
    setRecipients([...recipients, null]);
    setRecipientKeys([...recipientKeys, crypto.randomUUID()]);
  };

  const onRemoveRecipientClicked = (index: number) => {
    setRecipients(recipients.filter((__, i) => i !== index));
    setNumRecipients(numRecipients - 1);
    setRecipientKeys(recipientKeys.filter((__, i) => i !== index));
  };

  const allRecipientsValid = recipients.every((r) => r !== null);

  const onNextClicked = () => {
    onNextClickedInput(recipients as Recipient[]);
  };

  const onEnterPressed = () => {
    if (!allRecipientsValid) {
      return;
    }
    if (busy) {
      return;
    }
    onNextClicked();
  };

  const getRecipientEntryRow = (index: number, includeRemove: boolean) => (
    <RecipientEntryRow
      key={recipientKeys[index]}
      index={index}
      onEntryUpdated={(r) => onRecipientUpdated(index, r)}
      onRemoveClicked={
        includeRemove ? () => onRemoveRecipientClicked(index) : null
      }
      onEnterPressed={onEnterPressed}
    />
  );

  const getRecipientEntryRows = () => {
    const toReturn = [];
    for (let i = 0; i < numRecipients; i += 1) {
      toReturn.push(getRecipientEntryRow(i, numRecipients > 1));
    }
    return toReturn;
  };

  return (
    <PFormScreen
      title="Add Recipients (OPTIONAL)"
      tooltip={
        "We will send out an email (with you CC'ed) to anyone you add as a recipient to this quiz " +
        "with instructions for responding. We will also give you a link that you can share out " +
        "yourself, so adding recipients is not necessary.\n\nYou can also add recipients later."
      }
      onBackClicked={onBackClicked}
      backDisabled={busy}
      onNextClicked={onNextClicked}
      nextDisabled={!allRecipientsValid || busy}
      onSkipClicked={onSkipClicked}
      skipDisabled={busy}
    >
      <div className="flex flex-col gap-3">
        {getRecipientEntryRows()}
        {numRecipients >= maxRecipients && (
          <div className="italic text-sm text-center">
            You've reached the maximum number of recipients
          </div>
        )}
        <PPlusButton
          kind="primary"
          onClick={onAddRecipientClicked}
          disabled={busy || numRecipients >= maxRecipients}
          text="add another"
        />
      </div>
    </PFormScreen>
  );
};

export default RecipientEntryScreenComponent;
