type QueryData = Record<string, string | number | undefined>;

export const parseQueryString = <T extends QueryData>(
  template: T,
): NonNullable<T> => {
  const queryParams = new URLSearchParams(window.location.search);
  const result = {} as NonNullable<T>;

  Object.keys(template).forEach((key) => {
    if (queryParams.has(key)) {
      const value = queryParams.get(key);

      if (value !== null) {
        const fieldType = typeof template[key];

        // Parse value based on the expected type
        if (fieldType === "number") {
          const parsedValue = parseFloat(value);
          if (!Number.isNaN(parsedValue)) {
            result[key as keyof T] = parsedValue as NonNullable<T>[typeof key];
          }
        } else if (fieldType === "string") {
          result[key as keyof T] = value as NonNullable<T>[typeof key];
        }
      }
    }
  });

  return result;
};

export const updateQueryString = <T extends QueryData>(data: T): void => {
  const queryParams = new URLSearchParams();

  (Object.keys(data) as (keyof T)[]).forEach((key) => {
    const value = data[key];
    if (value !== undefined && value !== null) {
      queryParams.set(key as string, String(value));
    }
  });

  // Update the current URL without reloading the page
  const newUrl =
    window.location.pathname +
    (queryParams.toString() ? `?${queryParams.toString()}` : "");
  window.history.replaceState(null, "", newUrl);
};
