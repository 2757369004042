import React, { ReactElement, useRef, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import PButton, { PButtonKind } from "../buttons/PButton";
import PBaseDialog from "./PBaseDialog";
import { classNames } from "../../../util/strings";

export type PConfirmationDialogProps = {
  title?: string | null;
  body?: string | ReactElement | null;
  confirmText?: string | null;
  kind?: "danger" | "warning" | "info";
};

type innerProps = PConfirmationDialogProps & {
  onConfirm: () => void;
  onCancel?: (() => void) | null;
};

const PConfirmationDialogComponent = (props: innerProps) => {
  const { title, body, confirmText, kind, onConfirm, onCancel } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const cancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const confirm = () => {
    setIsOpen(false);
    onConfirm();
  };

  const getButtonKind = (): PButtonKind => {
    if (kind === "danger") {
      return "danger";
    }
    if (kind === "warning") {
      return "warning";
    }
    return "primary";
  };

  const getIconClass = (): string => {
    if (kind === "danger") {
      return "text-danger";
    }
    if (kind === "warning") {
      return "text-warning";
    }
    return "text-primary";
  };

  const cancelButtonRef = useRef(null);

  return (
    <PBaseDialog
      title={title ?? "Are you sure?"}
      titleIcon={
        <ExclamationTriangleIcon
          className={classNames("h-10 w-10", getIconClass())}
          aria-hidden="true"
        />
      }
      show={isOpen}
      onClose={cancel}
    >
      <p className="text-sm text-gray-500 max-w-md">
        {body ?? "There is no undo."}
      </p>
      <div className="flex flex-row w-full gap-4">
        <PButton
          className="w-1/2"
          kind="secondary"
          onClick={() => cancel()}
          ref={cancelButtonRef}
        >
          cancel
        </PButton>
        <PButton className="w-1/2" kind={getButtonKind()} onClick={confirm}>
          {confirmText ?? "confirm"}
        </PButton>
      </div>
    </PBaseDialog>
  );
};

PConfirmationDialogComponent.defaultProps = {
  title: null,
  body: null,
  confirmText: null,
  onCancel: null,
  kind: "warning",
};

export default PConfirmationDialogComponent;
