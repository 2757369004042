import React from "react";
import { HomeIcon } from "@heroicons/react/20/solid";

export type breadcrumbEntry = {
  name: string;
  url?: string | null;
};

export type PBreadcrumbsProps = {
  homeUrl: string;
  entries: breadcrumbEntry[];
};

const PBreadcrumbsComponent = (props: PBreadcrumbsProps) => {
  const { homeUrl, entries } = props;

  const getCrumb = (entry: breadcrumbEntry, index: number) => {
    if (entry.url) {
      return (
        <a
          key={index}
          href={entry.url}
          className="text-p-black-lighter hover:text-primary ml-2"
        >
          {entry.name}
        </a>
      );
    }
    return (
      <span key={index} className="text-p-black-lighter ml-2">
        {entry.name}
      </span>
    );
  };

  return (
    <nav aria-label="Breadcrumb" className="flex text-sm">
      <ol className="flex items-center space-x-2">
        <li>
          <div>
            <a
              href={homeUrl}
              className="text-p-black-lighter hover:text-primary"
            >
              <HomeIcon aria-hidden="true" className="size-5 shrink-0" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {entries.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
                className="size-5 shrink-0 text-gray-300"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {getCrumb(page, index)}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default PBreadcrumbsComponent;
