import React, { ReactNode } from "react";
import PPlusButton from "../../../ui/buttons/PPlusButton";
import { useBusyWatcher } from "../../../../util/hooks";
import { components } from "../../../../api/spec";
import QuizSummaryRow from "./QuizSummaryRow";
import PErrors from "../../../ui/input/PErrors";
import { classNames } from "../../../../util/strings";

type ListQuizzesScreenProps = {
  onCreateNewClicked: () => void;
  quizzes: components["schemas"]["ListQuizzesResponseSerializerWrapper"] | null;
  errors: string[];
  viewQuizUrl: string;
  viewReportUrl: string;
  shareableUrl: string;
};

const ListQuizzesScreenComponent = (props: ListQuizzesScreenProps) => {
  const {
    onCreateNewClicked,
    quizzes,
    errors,
    viewQuizUrl,
    viewReportUrl,
    shareableUrl,
  } = props;

  const [busy, _] = useBusyWatcher();

  const getBody = (): ReactNode => {
    if (errors.length > 0) {
      return <PErrors errors={errors} />;
    }
    if (
      quizzes === null ||
      !quizzes.success ||
      quizzes.content.quizzes.length === 0
    ) {
      return (
        <div className="italic text-p-black-lightest">
          You haven't created any quizzes yet. Once you do they will be listed
          here.
        </div>
      );
    }
    return (
      <div className="flex flex-col divide-p-black-lightest/10 border-t border-b border-p-black-lightest/10 divide-y">
        {quizzes.content.quizzes.map((quiz, index) => (
          <div
            className={classNames(
              index % 2 === 0 ? "bg-primary/10" : null,
              "py-4 sm:py-2 px-2",
            )}
          >
            <QuizSummaryRow
              key={quiz.guid}
              quiz={quiz}
              viewUrl={viewQuizUrl}
              reportUrl={viewReportUrl}
              shareableUrl={shareableUrl}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold leading-6">Your Quizzes</h1>
          <p className="mt-2">
            A list of all the quizzes that you have created alongside their
            status
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <PPlusButton
            onClick={onCreateNewClicked}
            kind="primary"
            disabled={busy}
            text="new quiz"
          />
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full align-middle">
            {getBody()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListQuizzesScreenComponent;
