import React, { useState } from "react";
import PBanner, { BannerMessage } from "../PBanner";

type PBannerMessagesProps = {
  messages?: BannerMessage[];
};

const PBannerMessagesComponent = (props: PBannerMessagesProps) => {
  const { messages } = props;

  const [hiddenMessages, setHiddenMessages] = useState<number[]>([]);

  return (
    <>
      {messages!.map((message, index) => {
        if (!hiddenMessages.includes(index)) {
          return (
            <PBanner
              {...message}
              onClearClicked={() => {
                setHiddenMessages([...hiddenMessages, index]);
              }}
            />
          );
        }
        return null;
      })}
    </>
  );
};

PBannerMessagesComponent.defaultProps = {
  messages: [],
};

export default PBannerMessagesComponent;
