import React, { useEffect, useState } from "react";
import { components } from "../../../../api/spec";
import PBadge from "../../../ui/PBadge";
import { classNames } from "../../../../util/strings";
import { bgColorFromImportance } from "./common";

type TakeAwayListProps = {
  takeaways: components["schemas"]["SurveyTakeaway"][];
};

const TakeAwayListComponent = (props: TakeAwayListProps) => {
  const { takeaways: takeawaysInput } = props;

  const [takeaways, setTakeaways] = useState<
    components["schemas"]["SurveyTakeaway"][]
  >([]);

  const sortSurveyTakeaways = (
    toSort: components["schemas"]["SurveyTakeaway"][],
  ): components["schemas"]["SurveyTakeaway"][] => {
    const importanceOrder: Record<
      components["schemas"]["ImportanceEnum"],
      number
    > = {
      critical: 1,
      high: 2,
      medium: 3,
      low: 4,
    };

    return toSort.sort(
      (a, b) => importanceOrder[a.importance] - importanceOrder[b.importance],
    );
  };

  const parseContent = (
    content: components["schemas"]["ImportanceEnum"],
  ): string => {
    if (content === "critical") {
      return "essential";
    }
    return content;
  };

  useEffect(() => {
    setTakeaways(sortSurveyTakeaways(takeawaysInput));
  }, []);

  return (
    <>
      <div className="block sm:hidden flex flex-col gap-4">
        {takeaways.map((takeaway) => (
          <div
            className={classNames(
              "rounded-lg",
              bgColorFromImportance(takeaway.importance, true),
            )}
            key={takeaway.takeaway}
          >
            <div
              className={classNames(
                "rounded-t-lg px-2 py-1",
                bgColorFromImportance(takeaway.importance),
              )}
            >
              {takeaway.importance}
            </div>
            <div className="px-2 py-1">{takeaway.takeaway}</div>
          </div>
        ))}
      </div>
      <ul className="hidden sm:block list-disc ml-5 space-y-4">
        {takeaways.map((takeaway) => (
          <li key={takeaway.takeaway}>
            <div className="flex flex-row items-center gap-2">
              <PBadge
                content={parseContent(takeaway.importance)}
                className="min-w-20 font-semibold"
                bgClass={bgColorFromImportance(takeaway.importance)}
              />
              <div className="text-p-black-lighter">{takeaway.takeaway}</div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default TakeAwayListComponent;
