import React, { useEffect, useRef, useState } from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import QuizQuestionCard from "../QuizQuestionCard";
import { components } from "../../../../api/spec";
import QuizQuestionCounter from "./QuizQuestionCounter";
import {
  useEditQuizQuestionDialog,
  useRegenerateQuestionsDialog,
} from "./hooks";
import PSkeleton from "../../../ui/PSkeleton";
import PButton from "../../../ui/buttons/PButton";

type QuizQuestionsScreenProps = {
  questions: components["schemas"]["ListQuizQuestionsResponseSerializerWrapper"];
  onNextClicked: (
    questions: components["schemas"]["SelectedQuizQuestion"][],
  ) => void;
  onRegenerateClicked: (
    questions: components["schemas"]["SelectedQuizQuestion"][],
    reason: string,
  ) => void;
  isRegenerating?: boolean;
};

const QuizQuestionScreenComponent = (props: QuizQuestionsScreenProps) => {
  const {
    questions: questionsInput,
    onNextClicked,
    onRegenerateClicked,
    isRegenerating,
  } = props;

  const [questions, setQuestions] = useState<
    components["schemas"]["SelectedQuizQuestion"][]
  >([]);
  const centerColumnRef = useRef<HTMLDivElement>(null);
  const [statsPosition, setStatsPosition] = useState<number>(0);
  const editQuestionDialog = useEditQuizQuestionDialog();
  const regenerateQuestionsDialog = useRegenerateQuestionsDialog();
  const [busy, _] = useBusyWatcher();

  useEffect(() => {
    setQuestions(questionsInput.content.questions);
  }, [questionsInput]);

  const onQuestionSelected = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index].is_selected = !newQuestions[index].is_selected;
    setQuestions(newQuestions);
  };

  const onEditQuestionClicked = async (questionIndex: number) => {
    const initialQuestion = questions[questionIndex];
    const updatedQuestion = await editQuestionDialog.showEditQuestionDialog({
      question: questions[questionIndex].question,
    });
    if (updatedQuestion === null) {
      return;
    }
    const newQuestions = [...questions];

    // TODO - If the question hasn't changed then we don't need to update the source

    let questionSource = initialQuestion.source;
    if (questionSource === "gen_via_llm") {
      questionSource = "gen_then_edited";
    } else if (questionSource === "regen_via_llm") {
      questionSource = "regen_then_edited";
    }
    newQuestions[questionIndex] = {
      question: updatedQuestion,
      source: questionSource,
      is_selected: initialQuestion.is_selected,
    };
    setQuestions(newQuestions);
  };

  const countSelectedQuestions = () =>
    questions.reduce(
      (count, question) => (question.is_selected ? count + 1 : count),
      0,
    );

  const selectedQuestionCount = countSelectedQuestions();

  const canSubmit = () =>
    selectedQuestionCount >= questionsInput.content.min_selection_count &&
    selectedQuestionCount <= questionsInput.content.max_selection_count;

  useEffect(() => {
    const updatePosition = () => {
      if (centerColumnRef.current) {
        const columnRightEdge =
          centerColumnRef.current.getBoundingClientRect().right;
        setStatsPosition(columnRightEdge + 50);
      }
    };

    // Initial position
    updatePosition();

    // Update position on window resize
    window.addEventListener("resize", updatePosition);
    return () => window.removeEventListener("resize", updatePosition);
  }, []);

  const getQuestionsToShow =
    (): components["schemas"]["SelectedQuizQuestion"][] => {
      if (!isRegenerating) {
        return questions;
      }
      return questions.filter((q) => q.is_selected);
    };

  const onShowRegenerateDialogClicked = async () => {
    const reason =
      await regenerateQuestionsDialog.showRegenerateQuestionsDialog();
    if (reason === null) {
      return;
    }
    onRegenerateClicked(questions, reason);
  };

  return (
    <>
      <PFormScreen
        nextDisabled={busy || isRegenerating || !canSubmit()}
        onNextClicked={() => onNextClicked(questions)}
        title="Your Questions"
        tooltip={`Now you need to pick the questions you want to include in your quiz. We generated these questions specifically for you based on your core question, audience, and goal. You may choose between ${questionsInput.content.min_selection_count} and ${questionsInput.content.max_selection_count} questions to include in your quiz`}
      >
        <div ref={centerColumnRef} className="flex flex-col gap-3 mb-">
          {getQuestionsToShow().map((question, index) => (
            <QuizQuestionCard
              key={question.question.title}
              question={question.question}
              onSelected={() => onQuestionSelected(index)}
              selected={question.is_selected}
              disabled={
                busy ||
                isRegenerating ||
                (selectedQuestionCount >=
                  questionsInput.content.max_selection_count &&
                  !question.is_selected)
              }
              onEditClicked={() => onEditQuestionClicked(index)}
              disabledTooltip={
                selectedQuestionCount >=
                questionsInput.content.max_selection_count
                  ? "You've reached the maximum number of questions. Remove another question to add this one."
                  : null
              }
            />
          ))}
          {isRegenerating ? (
            <>
              <PSkeleton className="h-16" />
              <PSkeleton className="h-16" />
              <PSkeleton className="h-16" />
            </>
          ) : null}
        </div>
        <div className="sm:hidden fixed bottom-14 left-0 w-full px-4 pt-3 pb-1 bg-p-white">
          <QuizQuestionCounter
            minQuestionCount={questionsInput.content.min_selection_count}
            maxQuestionCount={questionsInput.content.max_selection_count}
            curQuestionCount={selectedQuestionCount}
          />
          <PButton
            className="mt-3 w-full"
            onClick={onShowRegenerateDialogClicked}
            disabled={
              busy || isRegenerating || !questionsInput.content.can_regenerate
            }
            disabledTooltip={
              questionsInput.content.cant_regenerate_reason ?? null
            }
          >
            regenerate questions
          </PButton>
        </div>
        {statsPosition !== 0 ? (
          <div
            className="fixed bottom-24 shadow shadow-p-black-lightest rounded-lg p-3 bg-p-white"
            style={{ left: `${statsPosition}px` }}
          >
            <QuizQuestionCounter
              minQuestionCount={questionsInput.content.min_selection_count}
              maxQuestionCount={questionsInput.content.max_selection_count}
              curQuestionCount={selectedQuestionCount}
            />
            <PButton
              className="mt-3 w-full"
              onClick={onShowRegenerateDialogClicked}
              disabled={
                busy || isRegenerating || !questionsInput.content.can_regenerate
              }
              disabledTooltip={
                questionsInput.content.cant_regenerate_reason ?? null
              }
            >
              regenerate questions
            </PButton>
          </div>
        ) : null}
      </PFormScreen>
      {editQuestionDialog.dialog}
      {regenerateQuestionsDialog.dialog}
    </>
  );
};

QuizQuestionScreenComponent.defaultProps = {
  isRegenerating: false,
};

export default QuizQuestionScreenComponent;
