import React from "react";
import PEditTextScreen from "../../../ui/screens/PEditTextScreen";

type QuizCoreQuestionScreenProps = {
  onNextClicked: (coreQuestion: string) => void;
  onBackClicked: () => void;
  errors: string[];
  content?: string | null;
};

const QuizCoreQuestionScreenComponent = (
  props: QuizCoreQuestionScreenProps,
) => {
  const { onNextClicked, onBackClicked, content, errors } = props;

  return (
    <PEditTextScreen
      title="What question do you need insights into?"
      tooltip="The 'core question' of a quiz is the core topic that you want to get insights into. It's the main idea you have in your head that you want to learn more about, described as precisely as you can."
      onNextClicked={onNextClicked}
      onBackClicked={onBackClicked}
      errors={errors}
      label="core question"
      placeholder={
        "ex: How can we improve user engagement on our platform?\nex: What should we do on our next family trip?\nex: How can we improve our block party next year?"
      }
      content={content}
      required
      long
    />
  );
};

QuizCoreQuestionScreenComponent.defaultProps = {
  content: null,
};

export default QuizCoreQuestionScreenComponent;
