export const numberToFixedLocaleString = (
  num: number,
  fractionDigits: number,
): string =>
  num.toLocaleString("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const numberToLocaleString = (num: number): string =>
  num.toLocaleString("en-US");
