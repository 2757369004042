import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useUniqueId } from "../../util/hooks";
import PTooltip from "./PTooltip";

type PTooltipIconProps = {
  tooltip: string;
};

const PTooltipIconComponent = (props: PTooltipIconProps) => {
  const { tooltip } = props;
  const tooltipId = useUniqueId();

  return (
    <>
      <QuestionMarkCircleIcon
        className="h-6 w-6 text-primary cursor-pointer hover:text-primary-lighter"
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltip}
      />
      <PTooltip id={tooltipId} place="bottom" />
    </>
  );
};

export default PTooltipIconComponent;
