import React from "react";
import PEditTextScreen from "../../../ui/screens/PEditTextScreen";

type QuizAudienceScreenProps = {
  onNextClicked: (audience: string) => void;
  onBackClicked: () => void;
  content?: string | null;
  errors: string[];
};

const QuizAudienceScreenComponent = (props: QuizAudienceScreenProps) => {
  const { onNextClicked, onBackClicked, content, errors } = props;

  return (
    <PEditTextScreen
      title="Who will you send this quiz to?"
      tooltip="By sharing information about who you intend to send this quiz to (ie: your 'audience') we'll be able to better design our questions and cater them to the recipients."
      onNextClicked={onNextClicked}
      errors={errors}
      onBackClicked={onBackClicked}
      label="audience"
      placeholder={
        "ex: Current customers who purchased in the last 6 months\nex: My immediate family and closest relatives\nex: The neighbors that live on my street"
      }
      content={content}
      required
      long
    />
  );
};

QuizAudienceScreenComponent.defaultProps = {
  content: null,
};

export default QuizAudienceScreenComponent;
