import React from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";

type QuizAlreadyCompletedScreenProps = {
  recipientName: string;
  quizTitle: string;
};

const QuizAlreadyCompletedScreenComponent = (
  props: QuizAlreadyCompletedScreenProps,
) => {
  const { recipientName, quizTitle } = props;

  return (
    <PFormScreen title={`'${quizTitle}' Already Completed`}>
      <div>
        Hey <span className="font-semibold">{recipientName}</span>!
      </div>
      <div>
        You've already submitted your responses for this quiz, so there's
        nothing left to do!
      </div>
      <div>Thanks for dropping by.</div>
    </PFormScreen>
  );
};

export default QuizAlreadyCompletedScreenComponent;
