import React, { useEffect, useRef, useState } from "react";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { useBusyWatcher } from "../../../util/hooks";

type PLoadingBarProps = {
  forceLoading?: boolean;
};

const PLoadingBarComponent = (props: PLoadingBarProps) => {
  const { forceLoading } = props;

  const [loadingBarRunning, setLoadingBarRunning] = useState<boolean>(false);
  const loadingBarRef = useRef<LoadingBarRef | null>(null);
  const [busy, _] = useBusyWatcher();

  useEffect(() => {
    if ((busy || forceLoading) && !loadingBarRunning) {
      loadingBarRef.current!.continuousStart();
      setLoadingBarRunning(true);
    }
    if (!busy && !forceLoading && loadingBarRunning) {
      loadingBarRef.current!.complete();
      setLoadingBarRunning(false);
    }
  }, [busy, forceLoading]);

  return (
    <LoadingBar
      height={4}
      shadow={false}
      color="#D699AF" // This needs to be kept in sync with tailwind.config.js
      ref={loadingBarRef}
    />
  );
};

PLoadingBarComponent.defaultProps = {
  forceLoading: false,
};

export default PLoadingBarComponent;
