import React from "react";
import { classNames } from "../../../util/strings";

type PLabelProps = {
  label: string;
  htmlFor?: string;
  required?: boolean;
  error?: boolean;
  includeBottomMargin?: boolean;
};

const PLabelComponent = (props: PLabelProps) => {
  const { label, required, htmlFor, error, includeBottomMargin } = props;

  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        "block text-left",
        error ? "text-danger" : null,
        includeBottomMargin ? "mb-3" : null,
      )}
    >
      {label}
      {required ? <span className="text-primary font-bold">*</span> : null}
    </label>
  );
};

PLabelComponent.defaultProps = {
  htmlFor: undefined,
  required: false,
  error: false,
  includeBottomMargin: true,
};

export default PLabelComponent;
