import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../util/strings";
import { useUniqueId } from "../../../util/hooks";
import PLabel from "./PLabel";

type PToggleProps = {
  onToggled: (enabled: boolean) => void;
  toggled?: boolean | null;
  disabled?: boolean;
  leftLabel?: string | null;
};

const PToggleComponent = (props: PToggleProps) => {
  const { onToggled, toggled: toggledInput, disabled, leftLabel } = props;

  const uniqueId = useUniqueId();
  const [toggled, setToggled] = useState<boolean>(toggledInput ?? false);

  const onClicked = () => {
    setToggled(!toggled);
    onToggled(!toggled);
  };

  const getSwitchBody = () => (
    <Switch
      checked={toggled}
      onChange={onClicked}
      disabled={disabled}
      className={classNames(
        "group relative inline-flex h-6 w-11 shrink-0 rounded-full",
        "border-2 border-transparent bg-p-black-lightest transition-colors duration-200",
        "ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2",
        "data-[checked]:bg-primary",
        disabled ? "cursor-not-allowed brightness-75" : "cursor-pointer",
      )}
      aria-labelledby={leftLabel ? uniqueId : undefined}
      aria-checked={toggled}
    >
      <span
        className={classNames(
          "pointer-events-none relative inline-block size-5 transform rounded-full",
          "bg-p-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5",
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            "absolute inset-0 flex size-full items-center justify-center transition-opacity",
            "duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100",
            "group-data-[checked]:ease-out",
          )}
        >
          <svg
            fill="none"
            viewBox="0 0 12 12"
            className="size-3 text-p-black-lighter"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          aria-hidden="true"
          className={classNames(
            "absolute inset-0 flex size-full items-center justify-center opacity-0",
            "transition-opacity duration-100 ease-out group-data-[checked]:opacity-100",
            "group-data-[checked]:duration-200 group-data-[checked]:ease-in",
          )}
        >
          <svg
            fill="currentColor"
            viewBox="0 0 12 12"
            className="size-3 text-primary"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );

  if (leftLabel) {
    return (
      <div className="flex items-center justify-between gap-3">
        <PLabel
          label={leftLabel}
          htmlFor={uniqueId}
          includeBottomMargin={false}
        />
        {getSwitchBody()}
      </div>
    );
  }

  return getSwitchBody();
};

PToggleComponent.defaultProps = {
  toggled: null,
  disabled: false,
  leftLabel: null,
};

export default PToggleComponent;
