import React, { useState } from "react";
import PBaseDialog from "../../../ui/dialogs/PBaseDialog";
import PButton from "../../../ui/buttons/PButton";
import { useBusyWatcher } from "../../../../util/hooks";
import PTextArea from "../../../ui/input/PTextArea";

type innerProps = {
  onRegenerateClicked: (reason: string) => void;
  onCancelClicked?: (() => void) | null;
};

const RegenerateQuestionsDialogComponent = (props: innerProps) => {
  const { onRegenerateClicked, onCancelClicked } = props;

  const [reason, setReason] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (reason.length === 0) {
      return false;
    }
    return true;
  };

  const emitReason = () => {
    setIsOpen(false);
    onRegenerateClicked(reason);
  };

  const cancel = () => {
    setIsOpen(false);
    if (onCancelClicked) {
      onCancelClicked();
    }
  };

  return (
    <PBaseDialog title="Regenerate Questions" show={isOpen} onClose={cancel}>
      <div className="flex flex-col gap-3 sm:w-screen sm:max-w-lg">
        <div className="text-left">
          This will regenerate all the questions that you did not select. Any
          questions that you <span className="font-semibold">did select</span>{" "}
          will remain.
        </div>
        <PTextArea
          label="What was wrong with the questions?"
          placeholder={`ex: Responses to these questions would not provide actionable feedback\nex: The questions were too vague\nex: The questions were not relevant to the feedback I'm looking to get`}
          resize={false}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          maxLength={256}
          required
        />
        <div className="flex flex-row w-full gap-4">
          <PButton className="w-1/2" kind="secondary" onClick={() => cancel()}>
            cancel
          </PButton>
          <PButton
            className="w-1/2"
            kind="primary"
            disabled={busy || !canSubmit()}
            onClick={emitReason}
          >
            regenerate
          </PButton>
        </div>
      </div>
    </PBaseDialog>
  );
};

RegenerateQuestionsDialogComponent.defaultProps = {
  onCancelClicked: null,
};

export default RegenerateQuestionsDialogComponent;
