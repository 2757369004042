import React, { PropsWithChildren, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PScreenShellProps } from "./common";
import { classNames } from "../../../util/strings";
import defaultProfilePic from "../../../assets/images/default_pfp.png";
import PLoadingBar from "./PLoadingBar";
import PBannerMessages from "./PBannerMessages";
import PButton from "../buttons/PButton";
import PDynamicIcon from "../PDynamicIcon";
import PBreadcrumbs, { PBreadcrumbsProps } from "../PBreadcrumbs";

type userNavEntry = {
  name: string;
  href: string;
};

type PSidebarScreenProps = PScreenShellProps & {
  breadcrumbs?: PBreadcrumbsProps | null;
};

const PSidebarScreenComponent = (
  props: PropsWithChildren<PSidebarScreenProps>,
) => {
  const { navbar, bgClassname, breadcrumbs, children } = props;

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const hasTopBarContent = Boolean(breadcrumbs);

  const getUserNavigation = (): userNavEntry[] => {
    const toReturn: userNavEntry[] = [];
    if (navbar.changeEmailUrl) {
      toReturn.push({ name: "Change email", href: navbar.changeEmailUrl });
    }
    if (navbar.logoutUrl) {
      toReturn.push({ name: "Sign out", href: navbar.logoutUrl });
    }
    return toReturn;
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <PLoadingBar forceLoading={navbar.forceLoading} />
      <PBannerMessages messages={navbar.messages} />
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-p-black-lightest/10 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />
          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="size-6 text-p-black-lighter"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-lighter px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <a href="/" className="cursor-pointer">
                    <img
                      className="h-8 w-auto"
                      src={navbar.logoUrl}
                      alt="Logo"
                    />
                  </a>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        {navbar.navItems.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-primary text-p-white"
                                  : "text-p-black hover:bg-primary hover:text-p-white",
                                "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold",
                              )}
                            >
                              <PDynamicIcon
                                iconName={item.icon}
                                className={classNames(
                                  item.current
                                    ? "text-white"
                                    : "text-p-black group-hover:text-white",
                                  "size-6 shrink-0",
                                )}
                              />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs/6 font-semibold text-p-black">
                        Your apps
                      </div>
                      <ul className="-mx-2 mt-2 space-y-1">
                        {navbar.appNavItems.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-primary text-white"
                                  : "text-p-black hover:bg-primary hover:text-white",
                                "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold",
                              )}
                            >
                              <span className="flex size-6 shrink-0 items-center justify-center rounded-lg border border-primary bg-primary-lightest text-[0.625rem] font-medium text-p-black">
                                {item.name.toUpperCase()[0]}
                              </span>
                              <span className="truncate">{item.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {navbar.settingsUrl ? (
                      <li className="mt-auto hidden">
                        <a
                          href={navbar.settingsUrl}
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-p-black hover:bg-primary hover:text-white"
                        >
                          <Cog6ToothIcon
                            aria-hidden="true"
                            className="size-6 shrink-0 text-p-black group-hover:text-white"
                          />
                          Settings
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-lighter px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <a href="/" className="cursor-pointer">
                <img className="h-8 w-auto" src={navbar.logoUrl} alt="Logo" />
              </a>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navbar.navItems.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-primary text-white"
                              : "text-p-black hover:bg-primary hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold",
                          )}
                        >
                          <PDynamicIcon
                            iconName={item.icon}
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-p-black group-hover:text-white",
                              "size-6 shrink-0",
                            )}
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                {navbar.appNavItems.length > 0 ? (
                  <li>
                    <div className="text-xs/6 font-semibold text-p-black">
                      Your apps
                    </div>
                    <ul className="-mx-2 mt-2 space-y-1">
                      {navbar.appNavItems.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-primary text-white"
                                : "text-p-black hover:bg-primary hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold",
                            )}
                          >
                            <span className="flex size-6 shrink-0 items-center justify-center rounded-lg border border-primary bg-primary-lightest text-[0.625rem] font-medium text-p-black">
                              {item.name.toUpperCase()[0]}
                            </span>
                            <span className="truncate">{item.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : null}
                {navbar.settingsUrl ? (
                  <li className="mt-auto hidden">
                    <a
                      href={navbar.settingsUrl}
                      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-p-black hover:bg-primary hover:text-white"
                    >
                      <Cog6ToothIcon
                        aria-hidden="true"
                        className="size-6 shrink-0 text-p-black group-hover:text-white"
                      />
                      Settings
                    </a>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div
            className={classNames(
              "sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8",
              navbar.transparent ? null : "border-b border-p-black-lightest/20",
            )}
          >
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-p-black-lighter lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>

            {/* Separator */}
            <div
              aria-hidden="true"
              className="h-6 w-px bg-p-black-lightest lg:hidden"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="grid flex-1 grid-cols-1 ">
                {breadcrumbs ? <PBreadcrumbs {...breadcrumbs} /> : null}
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-p-black-lighter hidden"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="size-6" />
                </button>

                {/* Separator */}
                <div
                  aria-hidden="true"
                  className={classNames(
                    "hidden lg:h-6 lg:w-px lg:bg-p-black-lightest",
                    hasTopBarContent ? "lg:block" : null,
                  )}
                />

                {!navbar.isAuthenticated && navbar.showSignIn && (
                  <div className="flex flex-col justify-center min-w-32">
                    <PButton href={navbar.loginUrl} kind="primary">
                      Sign In
                    </PButton>
                  </div>
                )}
                {/* Profile dropdown */}
                {navbar.isAuthenticated ? (
                  <Menu as="div" className="relative">
                    <MenuButton className="-m-1.5 flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full bg-p-black-lightest"
                        src={navbar.profilePicUrl || defaultProfilePic}
                        alt={navbar.profilePicName!}
                      />
                      <span className="hidden lg:flex lg:items-center">
                        <span
                          aria-hidden="true"
                          className="ml-4 text-sm/6 font-semibold text-p-black-lighter hidden"
                        >
                          Tom Cook
                        </span>
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="ml-2 size-5 text-p-black-lightest"
                        />
                      </span>
                    </MenuButton>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2.5 min-w-32 origin-top-right rounded-md bg-p-white py-2 shadow-lg ring-1 ring-p-black-lightest transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      {navbar.email ? (
                        <MenuItem key="email">
                          <div className="block px-3 py-1 text-sm/6 text-p-black-lighter">
                            {navbar.email}
                          </div>
                        </MenuItem>
                      ) : null}
                      {getUserNavigation().map((item) => (
                        <MenuItem key={item.name}>
                          <a
                            href={item.href}
                            className="block px-3 py-1 text-sm/6 text-p-black-lighter data-[focus]:bg-secondary-lightest data-[focus]:outline-none"
                          >
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Menu>
                ) : null}
              </div>
            </div>
          </div>

          <main className={classNames("py-10", bgClassname)}>
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

PSidebarScreenComponent.defaultProps = {
  breadcrumbs: null,
};

export default PSidebarScreenComponent;
