import React, { useEffect, useState } from "react";
import PInput from "../../ui/input/PInput";
import { isEmailValid } from "../../../util/validation";
import PTitle from "../../ui/PTitle";
import PIconCancelButton from "../../ui/buttons/PIconCancelButton";
import { useBusyWatcher } from "../../../util/hooks";
import { classNames } from "../../../util/strings";

export type Recipient = {
  firstName: string;
  lastName: string;
  emailAddress: string;
};

type RecipientEntryRowProps = {
  onEntryUpdated: (entry: Recipient | null) => void;
  onRemoveClicked?: (() => void) | null;
  index?: number | null;
  onEnterPressed: () => void;
  showTitle?: boolean;
};

const RecipientEntryRowComponent = (props: RecipientEntryRowProps) => {
  const { onEntryUpdated, onRemoveClicked, index, onEnterPressed, showTitle } =
    props;

  const [firstName, setFirstName] = useState<string>("");
  const [firstNameTouched, setFirstNameTouched] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameTouched, setLastNameTouched] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailAddressTouched, setEmailAddressTouched] =
    useState<boolean>(false);
  const [busy, _] = useBusyWatcher();

  const getFirstNameErrors = (): string[] => {
    const errors: string[] = [];
    if (firstNameTouched && firstName === "") {
      errors.push("First name is required");
    }
    return errors;
  };

  const getLastNameErrors = (): string[] => {
    const errors: string[] = [];
    if (lastNameTouched && lastName === "") {
      errors.push("Last name is required");
    }
    return errors;
  };

  const getEmailAddressErrors = (): string[] => {
    const errors: string[] = [];
    if (emailAddressTouched && emailAddress === "") {
      errors.push("Email address is required");
    }
    if (
      emailAddressTouched &&
      emailAddress.length > 0 &&
      !isEmailValid(emailAddress)
    ) {
      errors.push("Not a valid email address.");
    }
    return errors;
  };

  const hasErrors = (): boolean =>
    getFirstNameErrors().length > 0 ||
    getLastNameErrors().length > 0 ||
    getEmailAddressErrors().length > 0;

  const isEntryValid = (): boolean =>
    firstName.length > 0 && lastName.length > 0 && isEmailValid(emailAddress);

  const emitEntry = () => {
    if (isEntryValid()) {
      onEntryUpdated({
        firstName,
        lastName,
        emailAddress,
      });
    } else {
      onEntryUpdated(null);
    }
  };

  useEffect(() => {
    emitEntry();
  }, [firstName, lastName, emailAddress]);

  const getTitle = (): string => {
    if (index !== null && index !== undefined) {
      return `Recipient ${index + 1}`;
    }
    return "Recipient Info";
  };

  return (
    <div>
      {showTitle ? (
        <div
          className={classNames(
            "flex flex-row items-center justify-between p-2",
            hasErrors() ? "bg-danger/50" : "bg-secondary/20",
          )}
        >
          <PTitle title={getTitle()} />
          {onRemoveClicked ? (
            <PIconCancelButton
              tooltip="Remove this recipient"
              onClick={onRemoveClicked}
              disabled={busy}
              className="text-p-black"
            />
          ) : null}
        </div>
      ) : null}
      <div className="flex flex-col gap-2 p-2">
        <div className="flex flex-row gap-2">
          <PInput
            label="First Name"
            type="text"
            value={firstName}
            className="grow"
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={() => setFirstNameTouched(true)}
            errors={getFirstNameErrors()}
            maxLength={64}
            onEnterPressed={onEnterPressed}
          />
          <PInput
            label="Last Name"
            type="email"
            value={lastName}
            className="grow"
            onChange={(e) => setLastName(e.target.value)}
            onBlur={() => setLastNameTouched(true)}
            errors={getLastNameErrors()}
            maxLength={64}
            onEnterPressed={onEnterPressed}
          />
        </div>
        <div>
          <PInput
            label="Email Address"
            type="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            onBlur={() => setEmailAddressTouched(true)}
            errors={getEmailAddressErrors()}
            maxLength={128}
            onEnterPressed={onEnterPressed}
          />
        </div>
      </div>
    </div>
  );
};

RecipientEntryRowComponent.defaultProps = {
  onRemoveClicked: null,
  index: null,
  showTitle: true,
};

export default RecipientEntryRowComponent;
