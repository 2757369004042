import React, { forwardRef, ReactNode } from "react";
import { classNames } from "../../../util/strings";
import { useUniqueId } from "../../../util/hooks";
import PTooltip from "../PTooltip";

export type PButtonKind =
  | "primary"
  | "secondary"
  | "danger"
  | "warning"
  | "success";

export const getKindClass = (kind: PButtonKind, disabled: boolean): string => {
  if (disabled) {
    if (kind === "primary") {
      return `cursor-not-allowed bg-p-black-lightest text-p-white border-p-black-lighter font-semibold`;
    }
    return `cursor-not-allowed bg-p-black-lightest text-p-white border-p-black-lightest font-semibold`;
  }

  const hoverClass =
    "hover:brightness-105 active:brightness-85 hover:bg-opacity-95";
  switch (kind) {
    case "danger":
      return `${hoverClass} bg-danger text-secondary font-semibold border-danger-lighter`;
    case "warning":
      return `${hoverClass} bg-warning text-secondary font-semibold border-warning-lighter`;
    case "primary":
      return `${hoverClass} bg-p-black text-p-white font-semibold border-p-black-lighter`;
    case "success":
      return `${hoverClass} bg-success text-p-black font-semibold border-success-lighter`;
    case "secondary":
    default:
      return `${hoverClass} bg-secondary-lightest text-p-black font-semibold border-secondary-lighter`;
  }
};

type ButtonMetaProps = {
  kind?: PButtonKind;
  icon?: ReactNode;
  disabled?: boolean;
  disabledTooltip?: string | null;
};

export type ButtonButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonMetaProps & { href?: undefined };
type AnchorButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonMetaProps & { href: string };

export type PButtonProps = ButtonButtonProps | AnchorButtonProps;

const PButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, PButtonProps>(
  (
    {
      children,
      kind,
      disabled,
      className: classNameProp,
      icon: iconProp,
      disabledTooltip,
      ...props
    },
    ref,
  ) => {
    const className = classNames(
      "px-2 py-1 inline-flex flex-row items-center justify-center gap-2 border-2 rounded-lg whitespace-pre",
      getKindClass(kind!, disabled || false),
      classNameProp,
      children ? null : "w-9 h-9",
    );
    const uniqueId = useUniqueId();

    const getTooltip = (): string => {
      if (disabled) {
        return disabledTooltip ?? "";
      }
      return "";
    };

    const innards = iconProp ? (
      <>
        {iconProp}
        {children ? <span>{children}</span> : null}
      </>
    ) : (
      children
    );

    if (props.href) {
      return (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            ref={ref as React.Ref<HTMLAnchorElement>}
            className={className}
            onClick={(e) => {
              if (disabled) {
                e.preventDefault();
              }
            }}
            data-tooltip-id={uniqueId}
            data-tooltip-content={getTooltip()}
            {...props}
          >
            {innards}
          </a>
          <PTooltip id={uniqueId} />
        </>
      );
    }

    return (
      <>
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          ref={ref as React.Ref<HTMLButtonElement>}
          className={className}
          disabled={disabled}
          {...(props as ButtonButtonProps)}
          data-tooltip-id={uniqueId}
          data-tooltip-content={getTooltip()}
        >
          {innards}
        </button>
        <PTooltip id={uniqueId} />
      </>
    );
  },
);

PButton.defaultProps = {
  kind: "secondary",
  icon: undefined,
  disabled: undefined,
};

export default PButton;
