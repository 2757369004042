import { components, operations } from "../spec";
import { fetchAndUnpackError, useWrappersFetcher, Wrapper } from "../connector";

export const createQuiz = async (
  request: components["schemas"]["CreateQuizRequestInput"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["CreateQuizResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/create/")
      .method("post")
      .create()(request),
  );

export const listQuizQuestions = async (
  request: operations["reflection_api_quizzes_list_questions_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ListQuizQuestionsResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/list-questions/")
      .method("get")
      .create()(request),
  );

export const setQuizQuestions = async (
  request: components["schemas"]["SetQuizQuestionsRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/set-questions/")
      .method("post")
      .create()(request),
  );

export const setQuizRecipients = async (
  request: components["schemas"]["SetQuizRecipientsRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ReflectionQuizDetailSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/set-recipients/")
      .method("post")
      .create()(request),
  );

export const deleteQuizRecipient = async (
  request: components["schemas"]["DeleteQuizRecipientRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/delete-recipient/")
      .method("post")
      .create()(request),
  );

export const addQuizRecipient = async (
  request: components["schemas"]["AddRecipientToQuizRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    (
      | components["schemas"]["ReflectionQuizRecipientSummarySerializerWrapper"]
      | null
    ),
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/add-recipient/")
      .method("post")
      .create()(request),
  );

export const approveQuiz = async (
  request: components["schemas"]["ApproveQuizRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    (
      | components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"]
      | null
    ),
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/approve/")
      .method("post")
      .create()(request),
  );

export const listQuizzes = async (
  request: operations["reflection_api_quizzes_list_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ListQuizzesResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/list/")
      .method("get")
      .create()(request ?? {}),
  );

export const deleteQuiz = async (
  request: components["schemas"]["DeleteQuizRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/delete/")
      .method("post")
      .create()(request),
  );

export const getQuiz = async (
  request: operations["reflection_api_quizzes_get_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    (
      | components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"]
      | null
    ),
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/get/")
      .method("get")
      .create()(request ?? {}),
  );

export const getQuizRecipient = async (
  request: operations["reflection_api_quizzes_get_recipient_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["GetRecipientResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/get-recipient/")
      .method("get")
      .create()(request),
  );

export const markRecipientAsOpened = async (
  request: components["schemas"]["MarkRecipientAsOpenedRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/mark-recipient-opened/")
      .method("post")
      .create()(request),
  );

export const nudgeRecipient = async (
  request: components["schemas"]["NudgeRecipientRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/nudge-recipient/")
      .method("post")
      .create()(request),
  );

export const registerForQuiz = async (
  request: components["schemas"]["RegisterForQuizRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    (
      | components["schemas"]["ReflectionQuizRecipientSummarySerializerWrapper"]
      | null
    ),
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/register/")
      .method("post")
      .create()(request),
  );

export const checkQuizForClarifications = async (
  request: components["schemas"]["ClarifyQuizContentsInput"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ClarifyQuizContentsOutputSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/check-for-clarifications/")
      .method("post")
      .create()(request),
  );

export const closeQuiz = async (
  request: components["schemas"]["CloseQuizRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/close/")
      .method("post")
      .create()(request),
  );

export const recordParticipation = async (
  request: components["schemas"]["RecordParticipationRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/record-participation/")
      .method("post")
      .create()(request),
  );

export const regenerateQuizQuestions = async (
  request: components["schemas"]["RegenerateQuizQuestionsRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    (
      | components["schemas"]["RegenerateQuizQuestionsResponseSerializerWrapper"]
      | null
    ),
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/regenerate-questions/")
      .method("post")
      .create()(request),
  );
