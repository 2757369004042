import React from "react";
import PFilterBadge, { PFilterBadgeProps } from "./PFilterBadge";

export type PFilterBadgesProps = {
  badges: PFilterBadgeProps[];
};

const PFilterBadgesComponent = (props: PFilterBadgesProps) => {
  const { badges } = props;

  return (
    <div className="flex flex-row gap-3 flex-wrap grow">
      {badges.map((badge) => (
        <PFilterBadge key={`${badge.field}-${badge.value}`} {...badge} />
      ))}
    </div>
  );
};

export default PFilterBadgesComponent;
