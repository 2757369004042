import React, { ReactNode } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { classNames, snakeCaseToTitleCase } from "../../../util/strings";
import PTableRow, { PTableRowProps } from "./PTableRow";
import PPagination, { PPaginationProps } from "../PPagination";
import PSkeleton from "../PSkeleton";
import PFilterBadges, { PFilterBadgesProps } from "../PFilterBadges";
import PSearchBox from "../input/PSearchBox";

type PTableProps = {
  title?: string | null;
  description?: string | null;
  columns: string[];
  order?: string | null;
  onOrderByClicked?: ((ordering: string | null) => void) | null;
  rows: PTableRowProps[];
  includeActions?: boolean;
  pagination?: PPaginationProps | null;
  loading?: boolean;
  filters?: PFilterBadgesProps | null;
  searchTerm?: string | null;
  onSearchUpdated?: ((search: string | null) => void) | null;
  searchPlaceholder?: string;
};

const PTableComponent = (props: PTableProps) => {
  const {
    title,
    description,
    columns,
    order,
    onOrderByClicked,
    rows,
    includeActions,
    pagination,
    loading,
    filters,
    searchTerm,
    onSearchUpdated,
    searchPlaceholder,
  } = props;

  const hasTitleOrDescription = title !== null || description !== null;
  const hasTopActionsBar = Boolean(filters || onSearchUpdated);
  const hasContentAboveTable = hasTitleOrDescription || hasTopActionsBar;

  const getColumnHeader = (
    column: string,
    isFirst: boolean,
    isLast: boolean,
  ): ReactNode => {
    const columnTitle = snakeCaseToTitleCase(column);
    let elementBody: ReactNode;
    if (!onOrderByClicked) {
      elementBody = <div>{columnTitle}</div>;
    } else if (order === column) {
      elementBody = (
        <button
          type="button"
          onClick={() => onOrderByClicked(`-${column}`)}
          className="group inline-flex"
        >
          {columnTitle}
          <span className="ml-2 flex-none rounded bg-p-black-lightest/20 text-p-black-lighter group-hover:bg-p-black-lightest/50">
            <ChevronDownIcon aria-hidden="true" className="size-5" />
          </span>
        </button>
      );
    } else if (order === `-${column}`) {
      elementBody = (
        <button
          type="button"
          onClick={() => onOrderByClicked(null)}
          className="group inline-flex"
        >
          {columnTitle}
          <span className="ml-2 flex-none rounded bg-p-black-lightest/20 text-p-black-lighter group-hover:bg-p-black-lightest/50">
            <ChevronUpIcon aria-hidden="true" className="size-5" />
          </span>
        </button>
      );
    } else {
      elementBody = (
        <button
          type="button"
          onClick={() => onOrderByClicked(column)}
          className="group inline-flex"
        >
          {columnTitle}
          <span className="invisible ml-2 flex-none rounded text-p-black-lighter group-hover:visible group-focus:visible">
            <ChevronDownIcon aria-hidden="true" className="size-5" />
          </span>
        </button>
      );
    }
    if (isFirst) {
      return (
        <th
          scope="col"
          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-p-black-lighter sm:pl-0 whitespace-pre"
          key={column}
        >
          {elementBody}
        </th>
      );
    }

    // We only want to style the last column differently if we're not including actions, as that
    // actions column is added elsewhere

    if (isLast && !includeActions) {
      return (
        <th
          scope="col"
          className="relative py-3.5 pl-3 pr-0 font-semibold text-sm text-left whitespace-pre"
          key={column}
        >
          {elementBody}
        </th>
      );
    }
    return (
      <th
        scope="col"
        className="px-3 py-3.5 text-left text-sm font-semibold text-p-black-lighter whitespace-pre"
        key={column}
      >
        {elementBody}
      </th>
    );
  };

  const getLoadingRow = (key: string): ReactNode => {
    const columnCount = columns.length + (includeActions ? 1 : 0);
    const loadingColumns = Array.from({ length: columnCount }).map(
      (_, index) => ({
        key: `loading-column-${index}`,
        content: <PSkeleton className="h-5 " />,
      }),
    );
    return (
      <PTableRow
        pkey={key}
        columns={loadingColumns}
        includeActions={includeActions}
      />
    );
  };

  const getLoadingRows = (count = 10): ReactNode[] =>
    Array.from({ length: count }).map((_, index) =>
      getLoadingRow(`loading-row-${index}`),
    );

  return (
    <div>
      {hasTitleOrDescription ? (
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {title ? (
              <h1 className="text-base font-semibold text-p-black-lighter">
                {title}
              </h1>
            ) : null}
            {description ? (
              <p className="mt-2 text-sm text-p-black-lightest">
                {description}
              </p>
            ) : null}
          </div>
        </div>
      ) : null}
      {hasTopActionsBar ? (
        <div
          className={classNames(
            "flex flex-row items-end gap-3",
            hasTitleOrDescription ? "mt-4" : null,
          )}
        >
          {onSearchUpdated ? (
            <PSearchBox
              searchTerm={searchTerm}
              onSearchTermUpdated={onSearchUpdated}
              placeholder={searchPlaceholder}
            />
          ) : null}
          {filters ? <PFilterBadges {...filters} /> : null}
        </div>
      ) : null}
      <div
        className={classNames(
          "flow-root",
          hasContentAboveTable ? "mt-4" : null,
        )}
      >
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-p-black-lightest/40">
              <thead>
                <tr>
                  {columns.map((column, index) =>
                    getColumnHeader(
                      column,
                      index === 0,
                      index === columns.length - 1,
                    ),
                  )}
                  {includeActions ? (
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-0 font-semibold text-sm"
                      key="actions"
                      aria-label="actions"
                    >
                      &nbsp;
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody className="divide-y divide-p-black-lightest/40 bg-white">
                {loading
                  ? getLoadingRows()
                  : rows.map((row) => <PTableRow {...row} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination && !loading ? (
        <div className="mt-4">
          <PPagination {...pagination} />
        </div>
      ) : null}
    </div>
  );
};

PTableComponent.defaultProps = {
  title: null,
  description: null,
  order: null,
  onOrderByClicked: null,
  includeActions: false,
  pagination: null,
  loading: false,
  filters: null,
  searchTerm: null,
  onSearchUpdated: null,
  searchPlaceholder: "search",
};

export default PTableComponent;
