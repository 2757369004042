export const classNames = (...classes: (string | undefined | null)[]): string =>
  classes.filter(Boolean).join(" ");

export const truncateString = (str: string, maxLength = 20): string => {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength - 3)}...`;
};

export const durationFromMs = (ms: number): string => `duration-[${ms}ms]`;

export const shuffleStrings = (array: string[]): string[] =>
  array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export const prettyPrintJson = (jsonString: string | any): string => {
  if (typeof jsonString !== "string") {
    return JSON.stringify(jsonString, null, 2);
  }
  const parsed = JSON.parse(jsonString);
  return JSON.stringify(parsed, null, 2);
};

const specialSnakeCase = [
  "id",
  "uuid",
  "url",
  "api",
  "http",
  "https",
  "json",
  "xml",
  "yaml",
  "yml",
  "llm",
];

export const snakeCaseToTitleCase = (
  str: string,
  includeSpecial = true,
): string => {
  const inputElements = str.split("_").map((word) => word.toLowerCase());
  const outputElements = inputElements.map((word) => {
    if (includeSpecial && specialSnakeCase.includes(word)) {
      return word.toUpperCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return outputElements.join(" ");
};

export const convertToLocalTime = (datetime: string): string => {
  // Parse the input datetime string
  const date = new Date(datetime);

  // Get local timezone offset in hours
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  // Format the date in the user's local timezone
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formatted = formatter.formatToParts(date);

  // Extract and assemble the parts
  const parts: Record<string, string> = {};
  formatted.forEach(({ type, value }) => {
    if (type !== "literal") parts[type] = value;
  });

  // Get the local timezone abbreviation
  const timezone = Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName")?.value;

  return `${parts.month}/${parts.day}/${parts.year} ${parts.hour}:${parts.minute}:${parts.second} ${timezone}`;
};
