import { components, operations } from "../spec";
import { fetchAndUnpackError, useWrappersFetcher, Wrapper } from "../connector";

export const getQuizReport = async (
  request: operations["reflection_api_quizzes_get_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["GetQuizReportResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/reports/get/")
      .method("get")
      .create()(request),
  );

export const getSharedQuizReport = async (
  request: operations["reflection_api_reports_get_shared_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["GetQuizReportResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/reports/get-shared/")
      .method("get")
      .create()(request),
  );

export const getQuizReportSharingStatus = async (
  request: operations["reflection_api_reports_get_sharing_status_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    (
      | components["schemas"]["GetReportSharingStatusResponseSerializerWrapper"]
      | null
    ),
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/reports/get-sharing-status/")
      .method("get")
      .create()(request),
  );

export const setQuizReportSharingStatus = async (
  request: components["schemas"]["SetReportSharingStatusRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["EmptyResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/reports/set-sharing-status/")
      .method("post")
      .create()(request),
  );
