import React from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { CommonWellButtonProps } from "./common";
import PIconButton from "./PIconButton";

const PIconEditButtonComponent = (props: CommonWellButtonProps) => (
  <PIconButton {...props} icon={<PencilSquareIcon />} />
);

PIconEditButtonComponent.defaultProps = {
  disabled: false,
};

export default PIconEditButtonComponent;
