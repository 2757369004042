import React from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";

type QuizNotFoundScreenProps = {
  recipientName: string;
};

const QuizNotFoundScreenComponent = (props: QuizNotFoundScreenProps) => {
  const { recipientName } = props;

  return (
    <PFormScreen title="Quiz not found...">
      <div>
        hey <span className="font-semibold">{recipientName}</span>!
      </div>
      <div>it seems like the quiz you're looking for doesn't exist...</div>
      <div>please check the link and try again!</div>
    </PFormScreen>
  );
};

export default QuizNotFoundScreenComponent;
