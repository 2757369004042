import React from "react";
import { classNames } from "../../util/strings";
import PSkeleton from "./PSkeleton";

type PSkeletonListProps = {
  count: number;
  className?: string | null;
  gap: number;
};

const PSkeletonListComponent = (props: PSkeletonListProps) => {
  const { count, className, gap } = props;

  const gapClass = `gap-${gap}`;

  return (
    <div className={classNames("flex flex-col", gapClass)}>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PSkeleton className={className ?? undefined} key={index} />
        ))}
    </div>
  );
};

PSkeletonListComponent.defaultProps = {
  className: null,
};

export default PSkeletonListComponent;
