import * as HIcons from "@heroicons/react/24/outline";
import React, { ReactNode } from "react";

type PDynamicIconProps = {
  iconName?: string | null;
  className?: string | null;
};

const PDynamicIconComponent = (props: PDynamicIconProps) => {
  const { iconName, className } = props;

  const { ...icons } = HIcons;

  let finalIconName: string;

  if (iconName === null || iconName === undefined) {
    finalIconName = "QuestionMarkCircleIcon";
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  else if (!icons[iconName]) {
    finalIconName = "QuestionMarkCircleIcon";
  } else {
    finalIconName = iconName;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const TheIcon: ReactNode = icons[finalIconName];

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <TheIcon className={className} aria-hidden />
    </>
  );
};

PDynamicIconComponent.defaultProps = {
  iconName: null,
  className: null,
};

export default PDynamicIconComponent;
