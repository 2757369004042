import React, { Fragment, useRef } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../util/strings";
import defaultProfilePic from "../../../assets/images/default_pfp.png";
import PMiddleMarginsLayout from "./PMiddleMarginsLayout";
import PButton from "../buttons/PButton";
import PLoadingBar from "./PLoadingBar";
import { PNavItem, PNavProps } from "./common";
import PBannerMessages from "./PBannerMessages";

const PNav = (props: PNavProps) => {
  const {
    navItems,
    appNavItems,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    settingsUrl,
    logoutUrl, // Done
    loginUrl,
    changeEmailUrl, // Done
    profilePicUrl, // Done
    profilePicName, // Done
    isAuthenticated, // Done
    forceLoading, // Done
    logoUrl, // Done
    email, // Done
    messages, // Done
    transparent, // Done
    showSignIn,
  } = props;

  const navBarRef = useRef<HTMLElement | null>(null);

  const getTopNavItems = (): PNavItem[] => navItems;

  const getTopNavItemCount = (): number => getTopNavItems.length;

  const getBottopNavItemCount = (): number => {
    if (!isAuthenticated) {
      return 0;
    }
    return 1;
  };

  const hasNavItems: boolean =
    getTopNavItemCount() + getBottopNavItemCount() > 0;

  // We only want to show app nav items if more than one is available
  const hasAppNavItems: boolean = appNavItems.length > 1;

  return (
    <>
      <PLoadingBar forceLoading={forceLoading} />
      <PBannerMessages messages={messages} />
      <Disclosure
        ref={navBarRef}
        as="nav"
        className={classNames(transparent ? null : "bg-p-white shadow")}
      >
        {({ open }) => (
          <>
            <PMiddleMarginsLayout verticalPadding={false}>
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="/" className="cursor-pointer">
                      <img className="h-8 w-auto" src={logoUrl} alt="Logo" />
                    </a>
                  </div>
                </div>
                <div className="flex flex-row gap-3">
                  <div className="hidden lg:mr-6 lg:flex lg:space-x-8">
                    {getTopNavItems().map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          "inline-flex items-center border-b-4 px-1 pt-2 text-sm font-medium cursor-pointer",
                          item.current
                            ? "border-primary font-semibold"
                            : "border-transparent hover:border-primary text-p-black-lighter hover:text-p-black",
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  {!isAuthenticated && showSignIn && (
                    <div className="flex flex-col justify-center min-w-32">
                      <PButton href={loginUrl} kind="primary">
                        Sign In
                      </PButton>
                    </div>
                  )}
                  {hasNavItems && (
                    <div className="flex items-center lg:hidden px-2">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  )}
                  {isAuthenticated && (
                    <div className="hidden lg:flex lg:items-center">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-4 flex-shrink-0">
                        <div>
                          <Menu.Button className="relative flex rounded-full bg-p-white text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full bg-p-black-lightest"
                              src={profilePicUrl || defaultProfilePic}
                              alt={profilePicName!}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-p-white shadow-lg ring-1 ring-p-black-lightest focus:outline-none">
                            {hasAppNavItems
                              ? appNavItems.map((item, index) => (
                                  <Menu.Item>
                                    {({ focus }) => (
                                      <a
                                        href={item.href}
                                        className={classNames(
                                          focus
                                            ? "bg-secondary-lightest"
                                            : null,
                                          index === 0 ? "rounded-t-lg" : null,
                                          "block px-4 py-2 text-sm",
                                        )}
                                      >
                                        {item.name.toLowerCase()}
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))
                              : null}
                            {/* <Menu.Item> */}
                            {/*  {({ active }) => ( */}
                            {/*    <a */}
                            {/*      href={settingsUrl} */}
                            {/*      className={classNames( */}
                            {/*        active ? "bg-primary/20" : "", */}
                            {/*        "block px-4 py-2 text-sm", */}
                            {/*      )} */}
                            {/*    > */}
                            {/*      settings */}
                            {/*    </a> */}
                            {/*  )} */}
                            {/* </Menu.Item> */}
                            <Menu.Item>
                              {({ focus }) => (
                                <a
                                  href={changeEmailUrl}
                                  className={classNames(
                                    focus ? "bg-secondary-lightest" : null,
                                    hasAppNavItems ? "rounded-t-lg" : null,
                                    "block px-4 py-2 text-sm",
                                  )}
                                >
                                  change email
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ focus }) => (
                                <a
                                  href={logoutUrl}
                                  className={classNames(
                                    focus ? "bg-secondary-lightest" : null,
                                    "block px-4 py-2 text-sm rounded-b-lg",
                                  )}
                                >
                                  sign out
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </div>
              </div>
            </PMiddleMarginsLayout>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 pb-3 pt-2 bg-p-white">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                {navItems.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                      item.current
                        ? "border-primary bg-primary-light text-primary font-semibold"
                        : "border-transparent hover:border-primary hover:bg-secondary-lightest",
                    )}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              {isAuthenticated && (
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full bg-p-black-lightest"
                        src={profilePicUrl || defaultProfilePic}
                        alt={profilePicName!}
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium">
                        {profilePicName}
                      </div>
                      <div className="text-sm font-medium">{email}</div>
                    </div>
                  </div>
                  <div className="space-y-1 mt-3">
                    {/* <Disclosure.Button */}
                    {/*  as="a" */}
                    {/*  href={settingsUrl} */}
                    {/*  className="block px-4 py-2 text-base font-medium hover:bg-primary/5" */}
                    {/* > */}
                    {/*  settings */}
                    {/* </Disclosure.Button> */}
                    <Disclosure.Button
                      as="a"
                      href={logoutUrl}
                      className="w-full text-left block px-4 py-2 text-base font-medium hover:bg-secondary-lightest"
                    >
                      sign out
                    </Disclosure.Button>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

PNav.defaultProps = {
  profilePicUrl: undefined,
  profilePicName: undefined,
  forceLoading: false,
  logoUrl: undefined,
  email: undefined,
  messages: [],
  transparent: false,
  showSignIn: true,
};

export default PNav;
