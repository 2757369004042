import React from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import TakeAwayList from "./TakeAwayList";

type ReportKeyInsightsCardProps = {
  report: components["schemas"]["GetQuizReportResponseSerializerWrapper"];
};

const ReportKeyInsightsCardComponent = (props: ReportKeyInsightsCardProps) => {
  const { report } = props;

  return (
    <PCard>
      <div className="flex flex-col gap-3">
        <PTitle className="whitespace-pre" title="🔎 Key Insights" />
        <TakeAwayList takeaways={report.content.report.key_insights} />
      </div>
    </PCard>
  );
};

export default ReportKeyInsightsCardComponent;
