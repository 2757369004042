import React, { useCallback, useState } from "react";
import ReportSummaryDialog, {
  ReportSummaryDialogProps,
} from "./ReportSummaryDialog";
import ReportSharingDialog, {
  ReportSharingDialogProps,
} from "./ReportSharingDialog";

export const useReportQuestionDialog = () => {
  const [show, setShow] = useState<boolean>(false);

  const [props, setProps] = useState<ReportSummaryDialogProps | null>(null);

  const [promiseResolve, setPromiseResolve] = useState<
    ((confirmed: boolean) => void) | null
  >(null);

  const showQuestion = useCallback(
    async (innerProps: ReportSummaryDialogProps) => {
      setProps(innerProps);
      setShow(true);

      return new Promise<boolean>((resolve) => {
        // NOTE: If you pass a callback to setState, React will call that callback,
        // so that's why this is wrapped in a pointless arrow function.
        setPromiseResolve(() => resolve);
      });
    },
    [],
  );

  return {
    dialog:
      show && props !== null ? (
        <ReportSummaryDialog
          question={props.question}
          onClose={() => {
            setShow(false);
            promiseResolve?.(true);
          }}
        />
      ) : null,
    showQuestion,
  };
};

export const useReportSharingDialog = () => {
  const [show, setShow] = useState<boolean>(false);
  const [props, setProps] = useState<ReportSharingDialogProps | null>(null);
  const showDialog = useCallback(
    async (innerProps: ReportSharingDialogProps) => {
      setProps(innerProps);
      setShow(true);
    },
    [],
  );
  return {
    dialog:
      show && props !== null ? (
        <ReportSharingDialog
          quizGuid={props.quizGuid}
          onClose={() => {
            setShow(false);
          }}
        />
      ) : null,
    showDialog,
  };
};
