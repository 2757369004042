import React from "react";
import { classNames } from "../../util/strings";

type Stat = {
  name: string;
  value: string;
  change?: string | null;
  changeType?: "positive" | "negative" | null;
};

type PDataRowProps = {
  stats: Stat[];
};

const PDataRowComponent = (props: PDataRowProps) => {
  const { stats } = props;

  return (
    <dl className="mx-auto grid grid-cols-1 gap-px bg-p-black-lightest/5 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-p-white px-4 py-10 sm:px-6 xl:px-8"
        >
          <dt className="text-sm/6 font-medium text-p-black-lightest">
            {stat.name}
          </dt>
          <dd
            className={classNames(
              stat.changeType === "negative" ? "text-danger" : "text-success",
              "text-xs font-medium",
            )}
          >
            {stat.change}
          </dd>
          <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-p-black-lighter">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default PDataRowComponent;
