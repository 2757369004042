import React, { useEffect, useState } from "react";
import { components } from "../../../../api/spec";
import ViewPromptScreen from "./ViewPromptScreen";
import PErrors from "../../../ui/input/PErrors";
import { getLLMPrompt } from "../../../../api/aiproxy/prompt";
import { PScreenShellProps } from "../../../ui/layout/common";
import PSidebarScreen from "../../../ui/layout/PSidebarScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import { PBreadcrumbsProps } from "../../../ui/PBreadcrumbs";
import { truncateString } from "../../../../util/strings";

type ViewPromptPageProps = PScreenShellProps & {
  listPromptsUrl: string;
  promptGuid: string;
};

const ViewPromptPageComponent = (props: ViewPromptPageProps) => {
  const { navbar, listPromptsUrl, promptGuid } = props;

  const [prompt, setPrompt] = useState<
    components["schemas"]["LLMPromptDetailSerializerWrapper"] | null
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const fetchPrompt = async () => {
    const [fetched, fetchErrors] = await getLLMPrompt({ prompt: promptGuid }, [
      busyWatcher,
    ]);
    if (fetchErrors !== null) {
      setErrors([fetchErrors.message!]);
      return;
    }
    setPrompt(fetched);
  };

  useEffect(() => {
    fetchPrompt();
  }, []);

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  const getPromptBreadcrumbTitle = (): string => {
    if (prompt === null) {
      return "Loading...";
    }
    return `Prompt '${truncateString(prompt.content.name, 15)}'`;
  };

  const getBreadrumbs = (): PBreadcrumbsProps => ({
    homeUrl: "/aiproxy/",
    entries: [
      { name: "Prompts", url: listPromptsUrl },
      { name: getPromptBreadcrumbTitle() },
    ],
  });

  return (
    <PSidebarScreen navbar={navbar} breadcrumbs={getBreadrumbs()}>
      <ViewPromptScreen prompt={prompt} />
    </PSidebarScreen>
  );
};

export default ViewPromptPageComponent;
