import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { components } from "../../../../api/spec";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  annotationPlugin, // Register the annotation plugin
);

type OpinionScaleBodyProps = {
  content: components["schemas"]["OpinionScaleResponsesSummary"];
};

const OpinionScaleBodyComponent = (props: OpinionScaleBodyProps) => {
  const { content } = props;

  const getRatingsValues = (): number[] => {
    const ratingsMap: { [key: number]: number } = {};

    content.ratings.forEach((rating) => {
      ratingsMap[rating.rating] = rating.count;
    });

    return [
      ratingsMap[1] || 0,
      ratingsMap[2] || 0,
      ratingsMap[3] || 0,
      ratingsMap[4] || 0,
      ratingsMap[5] || 0,
      ratingsMap[6] || 0,
      ratingsMap[7] || 0,
      ratingsMap[8] || 0,
      ratingsMap[9] || 0,
      ratingsMap[10] || 0,
    ];
  };

  const data = useMemo(
    () => ({
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      datasets: [
        {
          label: "Response Values",
          data: getRatingsValues(),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    }),
    [],
  );

  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        annotation: {
          annotations: {
            verticalLine: {
              type: "line" as const,
              xMin: content.average_rating - 1,
              xMax: content.average_rating - 1,
              borderColor: "red",
              borderWidth: 2,
              label: {
                enabled: true,
                content: `Average X: ${content.average_rating.toFixed(2)}`,
                position: "start" as const,
                backgroundColor: "rgba(255,255,255,0.7)",
                color: "red",
                padding: {
                  top: 10,
                  bottom: 10,
                },
              },
            },
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Rating",
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "# of Responses",
          },
        },
      },
    }),
    [],
  );

  return <Bar data={data} options={options} />;
};

export default OpinionScaleBodyComponent;
