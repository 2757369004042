import React from "react";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";
import { FunnelIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../util/strings";
import { clickableText } from "../../../util/style";

type PHoverableRowTextProps = {
  text: string;
  url?: string | null;
  onFilterClicked?: (() => void) | null;
  disabled?: boolean;
};

const PHoverableRowTextComponent = (props: PHoverableRowTextProps) => {
  const { text, url, onFilterClicked, disabled } = props;

  const hasItems = Boolean(url || onFilterClicked);

  return (
    <div className="group inline-flex flex-row gap-2 items-center">
      <span
        className={classNames(
          hasItems ? "font-semibold cursor-default" : null,
          hasItems && !disabled ? "text-primary" : null,
          hasItems && disabled ? "text-p-black-lightest" : null,
        )}
      >
        {text}
      </span>
      {url ? (
        <a
          className={classNames(
            "invisible flex-none rounded",
            clickableText,
            !disabled ? "group-hover:visible group-focus:visible" : null,
          )}
          aria-label={`View ${text}`}
          href={url}
        >
          <ArrowRightEndOnRectangleIcon aria-hidden="true" className="size-5" />
        </a>
      ) : null}
      {onFilterClicked ? (
        <button
          type="button"
          className={classNames(
            "invisible flex-none rounded",
            clickableText,
            !disabled ? "group-hover:visible group-focus:visible" : null,
          )}
          onClick={onFilterClicked}
          aria-label={`Filter for ${text}`}
        >
          <FunnelIcon aria-hidden="true" className="size-5" />
        </button>
      ) : null}
    </div>
  );
};

PHoverableRowTextComponent.defaultProps = {
  url: null,
  onFilterClicked: null,
  disabled: false,
};

export default PHoverableRowTextComponent;
