import React from "react";
import { classNames } from "../../util/strings";

export type PSubtitleProps = {
  title: string;
  className?: string | null;
};

const PSubtitleComponent = (props: PSubtitleProps) => {
  const { title, className } = props;

  return (
    <h1
      className={classNames(
        "text-sm font-semibold leading-6 text-p-black-lighter",
        className,
      )}
    >
      {title}
    </h1>
  );
};

PSubtitleComponent.defaultProps = {
  className: null,
};

export default PSubtitleComponent;
