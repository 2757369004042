import React, { ReactNode } from "react";
import { classNames } from "../../util/strings";
import { clickableText } from "../../util/style";

export type ListEntry = {
  key: string;
  value?: ReactNode;
};

export type headerLink = {
  title: string;
  url: string;
};

type PDescriptionListProps = {
  title?: string | null;
  description?: string | null;
  entries: ListEntry[];
  headerLinks?: headerLink[] | null;
};

const PDescriptionListComponent = (props: PDescriptionListProps) => {
  const { title, description, entries, headerLinks } = props;

  const hasTitleOrDescription = title !== null || description !== null;
  const hasHeaderLinks =
    headerLinks !== null && headerLinks !== undefined && headerLinks.length > 0;
  const hasTopBar = hasTitleOrDescription || hasHeaderLinks;

  return (
    <div>
      {hasTopBar ? (
        <div className="px-4 sm:px-0 flex flex-row items-end justify-between">
          {hasTitleOrDescription ? (
            <div>
              {title ? (
                <h3 className="text-base font-semibold leading-7 text-p-black-lighter">
                  {title}
                </h3>
              ) : null}
              {description ? (
                <p className="mt-1 max-w-2xl text-sm leading-6 text-p-black-lightest">
                  {description}
                </p>
              ) : null}
            </div>
          ) : null}
          {hasHeaderLinks ? (
            <div className="flex flex-row gap-3">
              {headerLinks!.map((link) => (
                <a href={link.url} key={link.title} className={clickableText}>
                  {link.title}
                </a>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={classNames(
          hasTopBar ? "mt-3 border-t border-b border-primary-lightest" : null,
        )}
      >
        <dl className="divide-y divide-primary-lightest">
          {entries.map((entry) => (
            <div
              key={entry.key}
              className="py-6 sm:grid sm:grid-cols-3 sm:gap-4"
            >
              <dt className="text-sm font-medium leading-6 text-p-black-lighter">
                {entry.key}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-p-black-lighter sm:col-span-2 sm:mt-0">
                {entry.value ?? "--"}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

PDescriptionListComponent.defaultProps = {
  title: null,
  description: null,
  headerLinks: null,
};

export default PDescriptionListComponent;
