import React, { useMemo } from "react";
import ReactJson from "react-json-view";

type PJsonProps = {
  content: string | object;
};

const PJsonComponent = (props: PJsonProps) => {
  const { content: contentInput } = props;

  const content = useMemo(
    () =>
      typeof contentInput === "string"
        ? JSON.parse(contentInput)
        : contentInput,
    [contentInput],
  );

  return (
    <ReactJson
      style={{ borderRadius: "12px", fontSize: "12px", padding: 5 }}
      src={content}
      theme="monokai"
      indentWidth={2}
      sortKeys
      quotesOnKeys={false}
    />
  );
};

export default PJsonComponent;
