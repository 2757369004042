import React, { useCallback, useState } from "react";
import { components } from "../../../../api/spec";
import EditQuizQuestionDialog, {
  EditQuizQuestionDialogProps,
} from "./EditQuizQuestionDialog";
import RegenerateQuestionsDialog from "./RegenerateQuestionsDialog";

export const useRegenerateQuestionsDialog = () => {
  const [show, setShow] = useState<boolean>(false);

  const [promiseResolve, setPromiseResolve] = useState<
    ((reason: string | null) => void) | null
  >(null);

  const showRegenerateQuestionsDialog = useCallback(async () => {
    setShow(true);

    return new Promise<string | null>((resolve) => {
      // NOTE: If you pass a callback to setState, React will call that callback,
      // so that's why this is wrapped in a pointless arrow function.
      setPromiseResolve(() => resolve);
    });
  }, []);

  return {
    dialog: show ? (
      <RegenerateQuestionsDialog
        onRegenerateClicked={(reason: string) => {
          setShow(false);
          promiseResolve?.(reason);
        }}
        onCancelClicked={() => {
          setShow(false);
          promiseResolve?.(null);
        }}
      />
    ) : null,
    showRegenerateQuestionsDialog,
  };
};

export const useEditQuizQuestionDialog = () => {
  const [show, setShow] = useState<boolean>(false);
  const [props, setProps] = useState<EditQuizQuestionDialogProps>({
    question: null,
  });
  const [promiseResolve, setPromiseResolve] = useState<
    ((question: components["schemas"]["QuizQuestion"] | null) => void) | null
  >(null);

  const showEditQuestionDialog = useCallback(
    async (innerProps: EditQuizQuestionDialogProps) => {
      setShow(true);
      setProps(innerProps);

      return new Promise<components["schemas"]["QuizQuestion"] | null>(
        (resolve) => {
          // NOTE: If you pass a callback to setState, React will call that callback,
          // so that's why this is wrapped in a pointless arrow function.
          setPromiseResolve(() => resolve);
        },
      );
    },
    [],
  );

  return {
    dialog: show ? (
      <EditQuizQuestionDialog
        onSaveClicked={(question: components["schemas"]["QuizQuestion"]) => {
          setShow(false);
          promiseResolve?.(question);
        }}
        onCancelClicked={() => {
          setShow(false);
          promiseResolve?.(null);
        }}
        {...props}
      />
    ) : null,
    showEditQuestionDialog,
  };
};
