import React from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import TakeAwayList from "./TakeAwayList";

type ReportNextStepsCardProps = {
  report: components["schemas"]["GetQuizReportResponseSerializerWrapper"];
};

const ReportNextStepsCardComponent = (props: ReportNextStepsCardProps) => {
  const { report } = props;

  return (
    <PCard>
      <div className="flex flex-col gap-3">
        <PTitle className="whitespace-pre" title="🗺️ Recommended Next Steps" />
        <TakeAwayList
          takeaways={report.content.report.next_steps.map((s) => ({
            takeaway: s.action,
            importance: s.importance,
          }))}
        />
      </div>
    </PCard>
  );
};

export default ReportNextStepsCardComponent;
