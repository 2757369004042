import React, { ReactNode } from "react";
import { components } from "../../../../api/spec";
import PSkeleton from "../../../ui/PSkeleton";
import PCode from "../../../ui/PCode";
import { prettyPrintJson } from "../../../../util/strings";
import PDescriptionList, { ListEntry } from "../../../ui/PDescriptionList";
import PJson from "../../../ui/PJson";
import { clickableText } from "../../../../util/style";
import PDurationSeconds from "../../../ui/PDurationSeconds";

type ViewInvocationScreenProps = {
  invocation:
    | components["schemas"]["LLMInvocationDetailSerializerWrapper"]
    | null;
};

const ViewInvocationScreenComponent = (props: ViewInvocationScreenProps) => {
  const { invocation } = props;

  const valueOrSkeleton = (
    propName: keyof components["schemas"]["LLMInvocationDetail"],
    skeletonClassName: string,
  ): ReactNode => {
    if (invocation === null) {
      return <PSkeleton className={skeletonClassName} />;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return invocation.content[propName];
  };

  const codeOrSkeleton = (
    propName: keyof components["schemas"]["LLMInvocationDetail"],
    fromJsonString: boolean,
    codeType: string,
    skeletonClassName: string,
  ): ReactNode => {
    if (invocation === null) {
      return <PSkeleton className={skeletonClassName} />;
    }
    const value = invocation.content[propName];
    if (!value) {
      return <PCode language="text" />;
    }
    let content: string;
    if (fromJsonString) {
      content = prettyPrintJson(value as string);
    } else {
      content = value as string;
    }
    if (codeType === "json") {
      return <PJson content={content} />;
    }
    return <PCode content={content} language={codeType} />;
  };

  const getEntries = (): ListEntry[] => [
    {
      key: "LLM Provider",
      value: valueOrSkeleton("llm_provider_name", "min-w-64 h-4"),
    },
    {
      key: "LLM Model",
      value: valueOrSkeleton("llm_model_name", "min-w-64 h-4"),
    },
    {
      key: "LLM Prompt",
      value: invocation?.content.llm_prompt_url ? (
        <a href={invocation.content.llm_prompt_url} className={clickableText}>
          {invocation.content.llm_prompt_name}
        </a>
      ) : (
        <PSkeleton className="min-w-64 h-4" />
      ),
    },
    {
      key: "Time Started",
      value: valueOrSkeleton("time_started", "min-w-64 h-4"),
    },
    {
      key: "Duration (Seconds)",
      value: invocation ? (
        <PDurationSeconds duration={invocation.content.duration_s} />
      ) : (
        <PSkeleton className="min-w-64 h-4" />
      ),
    },
    {
      key: "# of Input Tokens",
      value: valueOrSkeleton("num_input_tokens", "min-w-64 h-4"),
    },
    {
      key: "# of Output Tokens",
      value: valueOrSkeleton("num_output_tokens", "min-w-64 h-4"),
    },
    {
      key: "System Message",
      value: valueOrSkeleton("system_message", "min-w-64 h-4"),
    },
    {
      key: "Input Data",
      value: codeOrSkeleton("llm_input", true, "json", "min-w-64 h-36"),
    },
    {
      key: "Output Data",
      value: codeOrSkeleton("llm_output", true, "json", "min-w-64 h-36"),
    },
    {
      key: "Rendered Template",
      value: codeOrSkeleton(
        "rendered_template",
        false,
        "text",
        "min-w-64 h-36",
      ),
    },
  ];

  return (
    <PDescriptionList
      title={
        invocation === null
          ? "Loading..."
          : `Invocation ${invocation.content.guid}`
      }
      entries={getEntries()}
    />
  );
};

export default ViewInvocationScreenComponent;
