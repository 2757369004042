import React, { ReactNode } from "react";
import { classNames } from "../../util/strings";

type PBadgeProps = {
  content: ReactNode;
  onClick?: (() => void) | null;
  onCancelClick?: (() => void) | null;
  className?: string | null;
  disabled?: boolean;
  bgClass?: string;
};

const PBadgeComponent = (props: PBadgeProps) => {
  const { content, onClick, onCancelClick, disabled, bgClass, className } =
    props;

  const toReturn = (
    <span
      className={classNames(
        "inline-flex items-center px-2 py-1 text-xs font-medium rounded-full justify-center whitespace-pre",
        onClick && !disabled
          ? "hover:brightness-95 hover:underline text-primary ring-1 ring-inset ring-primary/20"
          : null,
        !disabled ? bgClass : "bg-disabled",
        className,
      )}
    >
      {content}
      {onCancelClick && (
        <button
          type="button"
          className={classNames(
            "group relative ml-1 -mr-1 h-3.5 w-3.5 rounded-lg",
            disabled ? "cursor-not-allowed" : "hover:bg-primary/20",
          )}
          onClick={onCancelClick}
          disabled={disabled}
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-p-black/50 group-hover:stroke-p-black/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      )}
    </span>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onClick} disabled={disabled}>
        {toReturn}
      </button>
    );
  }

  return toReturn;
};

PBadgeComponent.defaultProps = {
  onClick: null,
  onCancelClick: null,
  disabled: false,
  bgClass: "bg-secondary",
  className: null,
};

export default PBadgeComponent;
