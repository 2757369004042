import React from "react";
import TimeAgo from "react-timeago";

type PTimeAgoProps = {
  date: Date;
  className?: string;
};

const PTimeAgoComponent = (props: PTimeAgoProps) => {
  const { date, className } = props;
  return (
    <div className={className}>
      <TimeAgo date={date} />
    </div>
  );
};

PTimeAgoComponent.defaultProps = {
  className: undefined,
};

export default PTimeAgoComponent;
