import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useBusyWatcher } from "../../../../util/hooks";
import { components } from "../../../../api/spec";
import {
  addQuizRecipient,
  closeQuiz,
  deleteQuiz,
  deleteQuizRecipient,
  getQuiz,
  nudgeRecipient,
} from "../../../../api/reflection/quizzes";
import ViewQuizScreen from "./ViewQuizScreen";
import PNavbarScreen from "../../../ui/layout/PNavbarScreen";
import { Recipient } from "../RecipientEntryRow";
import { PScreenShellProps } from "../../../ui/layout/common";

type ViewQuizPageProps = PScreenShellProps & {
  quiz: string;
  quizzesUrl: string;
  reportUrl: string;
  shareableUrl: string;
};

const ViewQuizPageComponent = (props: ViewQuizPageProps) => {
  const { quiz, quizzesUrl, reportUrl, shareableUrl, navbar } = props;

  const [quizContent, setQuizContent] = useState<
    components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"] | null
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const fetchQuiz = async () => {
    const [response, error] = await busyWatcher(getQuiz({ quiz }));
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    setQuizContent(response);
  };

  const onNudgeClicked = async (
    recipient: components["schemas"]["ReflectionQuizRecipientSummary"],
  ) => {
    const [__, error] = await nudgeRecipient(
      {
        recipient: recipient.guid,
      },
      [busyWatcher],
    );
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    await fetchQuiz();
  };

  const onDeleteQuiz = async () => {
    const [__, error] = await deleteQuiz({ quiz }, [busyWatcher]);
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    window.location.href = quizzesUrl;
  };

  const onStopQuiz = async () => {
    const [__, error] = await closeQuiz({ quiz }, [busyWatcher]);
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    await fetchQuiz();
  };

  const onDeleteRecipient = async (
    recipient: components["schemas"]["ReflectionQuizRecipientSummary"],
  ) => {
    const [__, error] = await deleteQuizRecipient(
      {
        recipient: recipient.guid,
      },
      [busyWatcher],
    );
    if (error !== null) {
      setErrors([error.message!]);
      toast.error(`Failed to delete recipient: ${error.message}`);
      return;
    }
    await fetchQuiz();
  };

  const onAddRecipient = async (newRecipient: Recipient) => {
    const [__, error] = await addQuizRecipient(
      {
        quiz,
        first_name: newRecipient.firstName,
        last_name: newRecipient.lastName,
        email: newRecipient.emailAddress,
      },
      [busyWatcher],
    );
    if (error !== null) {
      setErrors([error.message!]);
      toast.error(`Failed to add recipient: ${error.message}`);
      return;
    }
    await fetchQuiz();
  };

  useEffect(() => {
    fetchQuiz();
  }, []);

  return (
    <PNavbarScreen navbar={navbar}>
      <ViewQuizScreen
        quiz={quizContent}
        errors={errors}
        reportUrl={reportUrl}
        onNudgeClicked={onNudgeClicked}
        shareableUrl={shareableUrl}
        onDelete={onDeleteQuiz}
        onStop={onStopQuiz}
        onDeleteRecipientClicked={onDeleteRecipient}
        onAddRecipientClicked={onAddRecipient}
      />
    </PNavbarScreen>
  );
};

export default ViewQuizPageComponent;
