import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { classNames } from "../../../util/strings";
import PFadeInOutTransition from "../transitions/PFadeInOutTransition";
import {
  middleColumnContent,
  middleColumnWrapper,
  middleFormContent,
  middleFormWrapper,
} from "../../../util/style";

type PCenterColumnScreenProps = {
  className?: string | null;
  fullWidth?: boolean;
  stickyFooterContent?: ReactNode | null;
};

const PCenterColumnScreenComponent = (
  props: PropsWithChildren<PCenterColumnScreenProps>,
) => {
  const { className, fullWidth, stickyFooterContent, children } = props;

  const [showFooter, setShowFooter] = useState<boolean>(false);

  useEffect(() => {
    if (!stickyFooterContent) {
      setShowFooter(false);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    setTimeout(() => {
      setShowFooter(true);
    }, 10);
    return () => setShowFooter(false);
  }, []);

  return (
    <div className={fullWidth ? middleColumnWrapper : middleFormWrapper}>
      <div
        className={classNames(
          fullWidth ? middleColumnContent : middleFormContent,
          "flex-1 flex flex-col p-6 bg-p-white rounded-lg border-2 border-p-black-lightest/50 mb-20",
          className,
        )}
      >
        {children}
        {stickyFooterContent && (
          <>
            <div className="grow sm:hidden" />
            <div className="mt-6 hidden sm:block">{stickyFooterContent}</div>
            {createPortal(
              <PFadeInOutTransition show={showFooter}>
                <div className="visible sm:invisible fixed bottom-0 left-0 w-full px-4 py-3 bg-p-white drop-shadow-m-white-up">
                  {stickyFooterContent}
                </div>
              </PFadeInOutTransition>,
              document.body,
            )}
          </>
        )}
      </div>
    </div>
  );
};

PCenterColumnScreenComponent.defaultProps = {
  className: null,
  fullWidth: false,
  stickyFooterContent: null,
};

export default PCenterColumnScreenComponent;
