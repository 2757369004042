import React, { forwardRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../util/strings";

type BannerLevel = "debug" | "info" | "success" | "warning" | "error";

// This maps Django's messages constant levels to our banner levels
// https://docs.djangoproject.com/en/5.0/ref/contrib/messages/#message-levels
const intToLevelMap: { [key: number]: BannerLevel } = {
  10: "debug",
  20: "info",
  25: "success",
  30: "warning",
  40: "error",
};

export type BannerMessage = {
  title?: string;
  message: string;
  level?: BannerLevel;
  levelInt?: number;
};

type PBannerProps = BannerMessage & {
  onClearClicked: () => void;
};

const PBannerComponent = forwardRef<HTMLDivElement, PBannerProps>(
  ({ title, message, level, levelInt, onClearClicked }, ref) => {
    const bannerLevel =
      level || (levelInt && intToLevelMap[levelInt]) || "info";

    const backgroundClassFromLevel = {
      debug: "bg-secondary",
      info: "bg-primary",
      success: "bg-success",
      warning: "bg-warning",
      error: "bg-danger",
    };

    const foregroundClassFromLevel = {
      debug: "text-p-black",
      info: "text-p-black",
      success: "text-p-black",
      warning: "text-p-white",
      error: "text-p-white",
    };

    return (
      <div
        ref={ref}
        className={classNames(
          "flex items-center gap-x-6 px-6 py-2.5 sm:px-3.5 sm:before:flex-1",
          backgroundClassFromLevel[bannerLevel],
        )}
      >
        <p
          className={classNames(
            "leading-6 font-semibold",
            foregroundClassFromLevel[bannerLevel],
          )}
        >
          {title ? (
            <>
              <strong className="font-semibold">{title}</strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
            </>
          ) : null}
          {message}
        </p>
        <div className="flex flex-1 justify-end">
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            onClick={onClearClicked}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon
              className={classNames(
                "h-5 w-5",
                foregroundClassFromLevel[bannerLevel],
              )}
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    );
  },
);

PBannerComponent.defaultProps = {
  title: undefined,
  level: undefined,
  levelInt: undefined,
};

export default PBannerComponent;
