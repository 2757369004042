// eslint-disable-next-line import/prefer-default-export
export const formatDuration = (seconds: number): string => {
  if (seconds < 60) {
    return `${parseFloat(seconds.toFixed(1))} seconds`;
  }
  if (seconds < 3600) {
    const minutes = seconds / 60;
    return `${parseFloat(minutes.toFixed(2))} minutes`;
  }
  if (seconds < 86400) {
    const hours = seconds / 3600;
    return `${parseFloat(hours.toFixed(2))} hours`;
  }
  const days = seconds / 86400;
  return `${parseFloat(days.toFixed(2))} days`;
};
