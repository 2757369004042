import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { shuffleStrings } from "../../util/strings";

type PLoadingTextProps = {
  phrases: string[];
  displayTime?: number;
  fadeDuration?: number;
};

const PLoadingTextComponent = (props: PLoadingTextProps) => {
  const { phrases: inputPhrases, displayTime, fadeDuration } = props;
  const [phrases, setPhrases] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showPhrase, setShowPhrase] = useState<boolean>(true); // Controls visibility for fade-out effect

  useEffect(() => {
    if (phrases.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    const interval = setInterval(() => {
      setShowPhrase(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setShowPhrase(true);
      }, fadeDuration);
    }, displayTime);

    return () => clearInterval(interval);
  }, [displayTime, fadeDuration, phrases.length]);

  useEffect(() => {
    const newPhrases = shuffleStrings(inputPhrases);
    setPhrases(newPhrases);
  }, []);

  return (
    <div className="flex items-center justify-center h-full">
      <Transition
        as={Fragment}
        show={showPhrase}
        enter={`transform transition duration-[${fadeDuration!}ms]`}
        enterFrom="opacity-0 rotate-[-5deg] text-primary"
        enterTo="opacity-100 rotate-0 scale-100 text-p-black-lighter"
        leave={`transform duration-${fadeDuration! / 2} transition ease-in-out`}
        leaveFrom="opacity-100 rotate-0 scale-100 text-p-black-lighter"
        leaveTo="opacity-0 rotate-[5deg] scale-95 text-primary"
      >
        <span className="inline-block">{phrases[currentIndex]}</span>
      </Transition>
    </div>
  );
};

PLoadingTextComponent.defaultProps = {
  displayTime: 2500,
  fadeDuration: 400,
};

export default PLoadingTextComponent;
