import React, { forwardRef, ReactNode, useEffect, useState } from "react";
import { useUniqueId } from "../../../util/hooks";
import PLabel from "./PLabel";
import { classNames } from "../../../util/strings";
import PErrors from "./PErrors";

export type PInputProps = React.ComponentPropsWithoutRef<"input"> & {
  label?: string | null;
  pre?: string;
  errors?: string[];
  onEnterPressed?: (() => void) | null;
  icon?: ReactNode;
};

const PInputComponent = forwardRef<HTMLInputElement, PInputProps>(
  (
    {
      label,
      required,
      pre,
      onEnterPressed,
      errors: errorsProp,
      className,
      icon,
      maxLength,
      value,
      ...props
    },
    ref,
  ) => {
    const labelId = useUniqueId();

    const [text, setText] = useState(value || "");

    useEffect(() => {
      setText(value || "");
    }, [value]);

    const getTextLength = (): number => {
      if (typeof text !== "string") {
        return 0;
      }
      return text.length;
    };

    const isTextLimitHit = (): boolean => {
      if (!maxLength) {
        return false;
      }
      if (maxLength - getTextLength() > 0) {
        return false;
      }
      return true;
    };

    const getErrors = (): string[] => {
      const errors = [];
      if (errorsProp) {
        errors.push(...errorsProp);
      }
      if (isTextLimitHit()) {
        errors.push("character limit reached");
      }
      return errors;
    };

    const errors = getErrors();
    const inError = Boolean(errors && errors.length > 0);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      let enterPressedHit = false;
      if (e.key === "Enter" && onEnterPressed) {
        onEnterPressed();
        enterPressedHit = true;
      }
      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
      if (enterPressedHit) {
        e.preventDefault();
      }
    };

    return (
      <div className={className}>
        {label ? (
          <PLabel
            label={label}
            htmlFor={labelId}
            required={required}
            error={inError}
          />
        ) : null}
        <div className="relative shadow-sm">
          {pre && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="sm:text-sm">{pre}</span>
            </div>
          )}
          {icon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {icon}
            </div>
          )}
          <input
            type="text"
            id={labelId}
            className={classNames(
              "block w-full border-0 px-3 py-1.5 ring-1 ring-inset placeholder:text-p-black-lightest focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 rounded-lg",
              pre ? "pl-7" : null,
              icon ? "pl-10" : null,
              inError ? "ring-danger" : "ring-p-black-lightest",
              props.disabled ? "cursor-not-allowed bg-gray-200" : null,
            )}
            ref={ref}
            onKeyDown={handleKeyDown}
            required={required}
            value={value}
            maxLength={maxLength}
            onChange={(event) => {
              setText(event.target.value);
              if (props.onChange) props.onChange(event);
            }}
            {...props}
          />
        </div>
        <PErrors className="mt-2" errors={errors} />
      </div>
    );
  },
);

PInputComponent.defaultProps = {
  label: null,
  pre: undefined,
  errors: undefined,
  onEnterPressed: null,
  icon: null,
};

export default PInputComponent;
