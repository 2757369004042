import React, { forwardRef } from "react";
import { useUniqueId } from "../../../util/hooks";
import { classNames } from "../../../util/strings";

type PCheckboxProps = React.ComponentPropsWithoutRef<"input"> & {
  label?: string | null;
};

const PCheckboxComponent = forwardRef<HTMLInputElement, PCheckboxProps>(
  ({ label, ...props }, ref) => {
    const uniqueId = useUniqueId();

    return (
      <div className="relative flex flex-row gap-3 items-center">
        {label && (
          <div className="text-sm leading-6">
            <label htmlFor={uniqueId} className="select-none font-medium">
              {label}
            </label>
          </div>
        )}
        <div className="flex h-6 items-center">
          <input
            ref={ref}
            id={uniqueId}
            type="checkbox"
            className={classNames(
              "h-6 w-6 border-p-black-lighter",
              props.disabled
                ? "text-p-black-lightest cursor-not-allowed focus:ring-p-black-lightest"
                : "text-primary focus:ring-primary cursor-pointer",
            )}
            {...props}
          />
        </div>
      </div>
    );
  },
);

PCheckboxComponent.defaultProps = {
  label: null,
};

export default PCheckboxComponent;
