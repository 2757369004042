import React, { useState } from "react";
import PFormScreen from "./PFormScreen";
import { useBusyWatcher } from "../../../util/hooks";
import PTextArea from "../input/PTextArea";
import { components } from "../../../api/spec";

export type QuestionAndAnswer = {
  question: string;
  answer: string;
};

type PQuestionsFormScreenProps = {
  questions: components["schemas"]["QuestionWithPlaceholder"][];
  title: string;
  comment?: string | null;
  onNextClicked: (answers: QuestionAndAnswer[]) => void;
  onSkipClicked?: (() => void) | null;
  tooltip?: string | null;
};

const PQuestionsFormScreenComponent = (props: PQuestionsFormScreenProps) => {
  const { questions, title, comment, onNextClicked, onSkipClicked, tooltip } =
    props;

  const [answers, setAnswers] = useState<QuestionAndAnswer[]>(
    questions.map((question) => ({ question: question.question, answer: "" })),
  );
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean =>
    answers.some((answer) => answer.answer.length > 0);

  const updateAnswer = (newAnswer: string, index: number) => {
    const newAnswers = [...answers];
    newAnswers[index].answer = newAnswer;
    setAnswers(newAnswers);
  };

  return (
    <PFormScreen
      title={title}
      comment={comment}
      onNextClicked={() => onNextClicked(answers)}
      nextDisabled={busy || !canSubmit()}
      onSkipClicked={onSkipClicked}
      tooltip={tooltip}
    >
      {answers.map((qa, index) => (
        <PTextArea
          key={qa.question}
          label={qa.question}
          value={qa.answer}
          placeholder={questions[index].placeholder}
          onChange={(answer) => updateAnswer(answer.target.value, index)}
          resize={false}
          disabled={busy}
        />
      ))}
    </PFormScreen>
  );
};

PQuestionsFormScreenComponent.defaultProps = {
  comment: null,
  onSkipClicked: null,
  tooltip: null,
};

export default PQuestionsFormScreenComponent;
