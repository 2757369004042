import React, { useEffect, useState } from "react";
import { PScreenShellProps } from "../../../ui/layout/common";
import { components } from "../../../../api/spec";
import { useBusyWatcher } from "../../../../util/hooks";
import { getLLMInvocation } from "../../../../api/aiproxy/prompt";
import PErrors from "../../../ui/input/PErrors";
import PSidebarScreen from "../../../ui/layout/PSidebarScreen";
import ViewInvocationScreen from "./ViewInvocationScreen";
import { PBreadcrumbsProps } from "../../../ui/PBreadcrumbs";

type ViewInvocationPageProps = PScreenShellProps & {
  listInvocationsUrl: string;
  invocationGuid: string;
};

const ViewInvocationPageComponent = (props: ViewInvocationPageProps) => {
  const { navbar, listInvocationsUrl, invocationGuid } = props;

  const [invocation, setInvocation] = useState<
    components["schemas"]["LLMInvocationDetailSerializerWrapper"] | null
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const fetchInvocation = async () => {
    const [fetched, fetchErrors] = await getLLMInvocation(
      { invocation: invocationGuid },
      [busyWatcher],
    );
    if (fetchErrors !== null) {
      setErrors([fetchErrors.message!]);
      return;
    }
    setInvocation(fetched);
  };

  useEffect(() => {
    fetchInvocation();
  }, []);

  const getInvocationBreadcrumbTitle = (): string => {
    if (invocation === null) {
      return "Loading...";
    }
    return `Invocation '${invocation.content.guid}'`;
  };

  const getBreadrumbs = (): PBreadcrumbsProps => ({
    homeUrl: "/aiproxy/",
    entries: [
      { name: "Invocations", url: listInvocationsUrl },
      { name: getInvocationBreadcrumbTitle() },
    ],
  });

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  return (
    <PSidebarScreen navbar={navbar} breadcrumbs={getBreadrumbs()}>
      <ViewInvocationScreen invocation={invocation} />
    </PSidebarScreen>
  );
};

export default ViewInvocationPageComponent;
