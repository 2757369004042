import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";

type PCodeProps = {
  content?: string | null;
  language: string;
};

const PCodeComponent = (props: PCodeProps) => {
  const { content: contentInput, language } = props;

  const content = contentInput ?? "\n\n\n\n\n";

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:next-line
    <SyntaxHighlighter
      customStyle={{
        fontSize: "12px",
        borderRadius: "12px",
      }}
      language={language}
      style={monokai}
      wrapLines
      wrapLongLines
    >
      {content}
    </SyntaxHighlighter>
  );
};

PCodeComponent.defaultProps = {
  content: null,
};

export default PCodeComponent;
