import React from "react";
import { classNames } from "../../../util/strings";

type PErrors = {
  errors?: string[];
  className?: string | null;
};

const PErrorsComponent = (props: PErrors) => {
  const { errors, className } = props;

  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className={classNames("flex flex-col", className)}>
      {errors.map((e) => (
        <div className="text-danger text-left text-xs" key={e}>
          {e}
        </div>
      ))}
    </div>
  );
};

PErrorsComponent.defaultProps = {
  errors: [],
  className: null,
};

export default PErrorsComponent;
