import React, { ReactNode } from "react";
import PBadge from "./PBadge";

export type PFilterBadgeProps = {
  field: string;
  value: string;
  onCancelClicked?: (() => void) | null;
  disabled?: boolean;
};

const PFilterBadgeComponent = (props: PFilterBadgeProps) => {
  const { field, value, onCancelClicked, disabled } = props;

  const getContent = (): ReactNode => (
    <div className="flex flex-row items-center gap-1">
      <span className="font-semibold">{field}:</span>
      <span className="text-p-black-lighter">{value}</span>
    </div>
  );

  return (
    <PBadge
      content={getContent()}
      onCancelClick={onCancelClicked}
      disabled={disabled}
    />
  );
};

PFilterBadgeComponent.defaultProps = {
  onCancelClicked: null,
  disabled: false,
};

export default PFilterBadgeComponent;
