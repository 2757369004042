export const DEFAULT_TRANSITION_ENTER_DURATION = 250;
export const DEFAULT_TRANSITION_LEAVE_DURATION = 150;
export const DEFAULT_TRANSITION_PADDING = 50;
export const MAX_STEP_TOOLTIP_LENGTH = 50;
export const PAGINATION_LIMIT = 10;
export const TEXT_BLUR_DELAY = 150;

export const LOADING_PHRASES: string[] = [
  "Crafting your quiz magic...",
  "Polishing quiz questions!",
  "Ensuring the LLM’s on its best behavior!",
  "Adding a sprinkle of creativity...",
  "Small brainstorm in progress...",
  "Fetching ideas (and coffee)...",
  "Checking quiz brilliance levels!",
  "Quick dog-walking break!",
  "Re-reading instructions (again)...",
  "Dusting off quiz questions!",
];
