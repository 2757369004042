import React from "react";
import { components } from "../../../../api/spec";
import PTitle from "../../../ui/PTitle";
import TakeAwayList from "./TakeAwayList";

type TextBodyProps = {
  content: components["schemas"]["TextResponsesSummary"];
};

const TextBodyComponent = (props: TextBodyProps) => {
  const { content } = props;

  return (
    <div className="flex flex-col gap-3">
      {content.takeaways.length > 0 ? (
        <div>
          <PTitle title="Takeaways" />
          <TakeAwayList takeaways={content.takeaways} />
        </div>
      ) : null}
      {content.executive_summary ? (
        <div>
          <PTitle title="Summary" />
          <div className="my-4">{content.executive_summary}</div>
        </div>
      ) : null}
      {content.quotes && content.quotes.length > 0 ? (
        <div className="flex flex-col gap-3">
          <PTitle title="Quotes" />
          {content.quotes.map((quote) => (
            <div className="p-2 rounded-lg bg-p-black/5 text-sm italic">
              {quote.quote}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default TextBodyComponent;
