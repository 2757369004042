import React from "react";
import { components } from "../../../../api/spec";
import { classNames } from "../../../../util/strings";
import PTitle from "../../../ui/PTitle";
import PSubtext from "../../../ui/PSubtext";

type PromptSummaryRowProps = {
  prompt: components["schemas"]["LLMPromptSummary"];
  onClick?: (() => void) | null;
  disabled?: boolean;
};

const PromptSummaryRowComponent = (props: PromptSummaryRowProps) => {
  const { prompt, onClick, disabled } = props;

  const body = (
    <div
      className={classNames(
        "w-full rounded-lg flex flex-col gap-2 text-left p-2",
        disabled
          ? "bg-disabled cursor-not-allowed"
          : "bg-secondary-lightest hover:bg-primary-lightest cursor-pointer",
      )}
    >
      <PTitle title={prompt.name} />
      <PSubtext text={prompt.description} />
    </div>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onClick} disabled={disabled}>
        {body}
      </button>
    );
  }

  return (
    <a
      href={prompt.url}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
    >
      {body}
    </a>
  );
};

PromptSummaryRowComponent.defaultProps = {
  onClick: null,
  disabled: false,
};

export default PromptSummaryRowComponent;
