import React, { useMemo } from "react";
import { components, operations } from "../../../../api/spec";
import { PTableRowProps } from "../../../ui/tables/PTableRow";
import { convertToLocalTime } from "../../../../util/strings";
import PTable from "../../../ui/tables/PTable";
import { numberToLocaleString } from "../../../../util/number";
import { clickableText } from "../../../../util/style";
import PHoverableRowText from "../../../ui/tables/PHoverableRowText";
import { PPaginationProps } from "../../../ui/PPagination";
import PDurationSeconds from "../../../ui/PDurationSeconds";
import { PFilterBadgesProps } from "../../../ui/PFilterBadges";

type InvocationsTableProps = {
  title?: string | null;
  description?: string | null;
  invocations: components["schemas"]["LLMInvocationSummary"][];
  disabled?: boolean;
  onFilterFieldClicked?:
    | ((
        field: keyof NonNullable<
          operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
        >,
        value: string,
        label: string,
      ) => void)
    | null;
  pagination?: PPaginationProps | null;
  loading?: boolean;
  filters?: PFilterBadgesProps | null;
  searchTerm?: string | null;
  onSearchUpdated?: ((search: string | null) => void) | null;
};

const InvocationsTableComponent = (props: InvocationsTableProps) => {
  const {
    title,
    description,
    invocations,
    disabled,
    onFilterFieldClicked,
    pagination,
    loading,
    filters,
    searchTerm,
    onSearchUpdated,
  } = props;

  const getRowForInvocation = (
    invocation: components["schemas"]["LLMInvocationSummary"],
  ): PTableRowProps => ({
    pkey: invocation.guid,
    includeActions: true,
    columns: [
      {
        key: "llm_prompt",
        content: invocation.llm_prompt_name ? (
          <PHoverableRowText
            text={invocation.llm_prompt_name}
            url={invocation.llm_prompt_url}
            onFilterClicked={
              onFilterFieldClicked
                ? () =>
                    onFilterFieldClicked(
                      "llm_prompt",
                      invocation.llm_prompt!,
                      invocation.llm_prompt_name!,
                    )
                : null
            }
            disabled={disabled}
          />
        ) : (
          "--"
        ),
      },
      {
        key: "time_started",
        content: invocation.time_started
          ? convertToLocalTime(invocation.time_started)
          : "--",
      },
      {
        key: "duration_s",
        content: invocation.duration_s ? (
          <PDurationSeconds duration={invocation.duration_s} />
        ) : (
          "--"
        ),
      },
      {
        key: "llm_provider",
        content: invocation.llm_provider_name ? (
          <PHoverableRowText
            text={invocation.llm_provider_name}
            // url={invocation.llm_provider_url}
            onFilterClicked={
              onFilterFieldClicked
                ? () =>
                    onFilterFieldClicked(
                      "llm_provider",
                      invocation.llm_provider!,
                      invocation.llm_provider_name!,
                    )
                : null
            }
            disabled={disabled}
          />
        ) : (
          "--"
        ),
      },
      {
        key: "llm_model",
        content: invocation.llm_model_name ? (
          <PHoverableRowText
            text={invocation.llm_model_name}
            // url={invocation.llm_model_url}
            onFilterClicked={
              onFilterFieldClicked
                ? () =>
                    onFilterFieldClicked(
                      "llm_model",
                      invocation.llm_model!,
                      invocation.llm_model_name!,
                    )
                : null
            }
            disabled={disabled}
          />
        ) : (
          "--"
        ),
      },
      {
        key: "Tokens",
        content: `${invocation.num_input_tokens ? numberToLocaleString(invocation.num_input_tokens) : "--"} / ${invocation.num_output_tokens ? numberToLocaleString(invocation.num_output_tokens) : "--"}`,
      },
      {
        key: "actions",
        content: (
          <a href={invocation.url} className={clickableText}>
            view
          </a>
        ),
      },
    ],
  });

  const rows = useMemo(
    () => invocations.map(getRowForInvocation),
    [invocations, disabled, onFilterFieldClicked],
  );

  return (
    <PTable
      title={title}
      description={description}
      columns={[
        "Prompt",
        "Time Started",
        "Execution Duration",
        "Provider",
        "Model",
        "Input / Output Tokens",
      ]}
      rows={rows}
      pagination={pagination}
      loading={loading}
      filters={filters}
      includeActions
      searchTerm={searchTerm}
      onSearchUpdated={onSearchUpdated}
    />
  );
};

InvocationsTableComponent.defaultProps = {
  title: null,
  description: null,
  disabled: false,
  onFilterFieldClicked: null,
  pagination: null,
  loading: false,
  filters: null,
  searchTerm: null,
  onSearchUpdated: null,
};

export default InvocationsTableComponent;
