import React, { useEffect, useState } from "react";
import PWizardScreen, {
  PWizardScreenPanel,
} from "../../../ui/layout/PWizardScreen";
import PLoadingScreen from "../../../ui/screens/PLoadingScreen";
import QuizRegistrationFormScreen, {
  RegistrantInfo,
} from "./QuizRegistrationFormScreen";
import { registerForQuiz } from "../../../../api/reflection/quizzes";
import { QUIZ_REGISTRANT_INFO_LOCAL_STORAGE_KEY } from "../../../../constants";
import { isEmailValid } from "../../../../util/validation";
import { classNames } from "../../../../util/strings";
import { backgroundGradient, middleCardSpacing } from "../../../../util/style";
import { useBusyWatcher } from "../../../../util/hooks";

enum RegisterForQuizStage {
  LOADING,
  GET_RECIPIENT_INFO,
  REGISTERING,
}

type RegisterForQuizPageProps = {
  quiz: string;
  takeQuizUrl: string;
};

const RegisterForQuizPageComponent = (props: RegisterForQuizPageProps) => {
  const { quiz, takeQuizUrl } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [stage, setStage] = useState<RegisterForQuizStage>(
    RegisterForQuizStage.LOADING,
  );
  const [_, busyWatcher] = useBusyWatcher();

  const clearRegistrantFromStorage = () => {
    localStorage.removeItem(QUIZ_REGISTRANT_INFO_LOCAL_STORAGE_KEY);
  };

  const writeRegistrantToLocalStorage = (registrant: RegistrantInfo) => {
    localStorage.setItem(
      QUIZ_REGISTRANT_INFO_LOCAL_STORAGE_KEY,
      JSON.stringify(registrant),
    );
  };

  const readRegistrantFromLocalStorage = (): RegistrantInfo | null => {
    const registrantInfo = localStorage.getItem(
      QUIZ_REGISTRANT_INFO_LOCAL_STORAGE_KEY,
    );
    if (registrantInfo === null) {
      return null;
    }
    const parsed = JSON.parse(registrantInfo);
    if (
      parsed.firstName === undefined ||
      parsed.firstName === "" ||
      parsed.lastName === undefined ||
      parsed.lastName === "" ||
      parsed.email === undefined ||
      parsed.email === "" ||
      !isEmailValid(parsed.email)
    ) {
      return null;
    }
    return parsed as RegistrantInfo;
  };

  const onRegisterClicked = async (
    registrant: RegistrantInfo,
  ): Promise<boolean> => {
    const [response, newErrors] = await registerForQuiz(
      {
        quiz,
        first_name: registrant.firstName,
        last_name: registrant.lastName,
        email: registrant.email,
      },
      [busyWatcher],
    );
    if (newErrors !== null) {
      setErrors([newErrors.message!]);
      return false;
    }
    writeRegistrantToLocalStorage(registrant);
    const recipientGuid = response!.content.guid;
    window.location.href = `${takeQuizUrl}?recipient=${recipientGuid}`;
    return true;
  };

  const goToStage = async (nextStage: RegisterForQuizStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case,no-empty
    switch (nextStage) {
    }
    setStage(nextStage);
  };

  const onLoad = async () => {
    const existing = readRegistrantFromLocalStorage();
    if (existing === null) {
      await goToStage(RegisterForQuizStage.GET_RECIPIENT_INFO);
      return;
    }
    setStage(RegisterForQuizStage.REGISTERING);
    const result = await onRegisterClicked(existing);
    if (!result) {
      clearRegistrantFromStorage();
      await goToStage(RegisterForQuizStage.GET_RECIPIENT_INFO);
    }
  };

  const getElements = (): PWizardScreenPanel[] => [
    {
      stage: RegisterForQuizStage.LOADING,
      content: <PLoadingScreen errors={errors} />,
    },
    {
      stage: RegisterForQuizStage.GET_RECIPIENT_INFO,
      content: (
        <QuizRegistrationFormScreen
          onRegisterClicked={onRegisterClicked}
          errors={errors}
        />
      ),
    },
    {
      stage: RegisterForQuizStage.REGISTERING,
      content: (
        <PLoadingScreen message="registering for quiz..." errors={errors} />
      ),
    },
  ];

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className={classNames(backgroundGradient, "min-h-full")}>
      <div className={middleCardSpacing}>
        <PWizardScreen elements={getElements()} stage={stage} />
      </div>
    </div>
  );
};

export default RegisterForQuizPageComponent;
