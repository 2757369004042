import React, { ReactNode } from "react";
import { classNames } from "../../../util/strings";

export type tableColumn = {
  key: string;
  content: ReactNode;
};

export type PTableRowProps = {
  pkey: string;
  columns: tableColumn[];
  includeActions?: boolean;
};

const PTableRowComponent = (props: PTableRowProps) => {
  const { pkey, columns, includeActions } = props;

  const getColumnElement = (
    column: tableColumn,
    isFirst: boolean,
    isLast: boolean,
  ): ReactNode => {
    if (isFirst) {
      return (
        <td
          key={column.key}
          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-p-black sm:pl-0"
        >
          {column.content}
        </td>
      );
    }
    if (isLast) {
      return (
        <td
          key={column.key}
          className={classNames(
            "relative whitespace-nowrap py-4 pl-3 pr-4 text-sm sm:pr-0",
            includeActions ? "text-right" : null,
          )}
        >
          {column.content}
        </td>
      );
    }
    return (
      <td
        key={column.key}
        className="whitespace-nowrap px-3 py-4 text-sm text-p-black-lighter"
      >
        {column.content}
      </td>
    );
  };

  return (
    <tr key={pkey}>
      {columns.map((column, index) =>
        getColumnElement(column, index === 0, index === columns.length - 1),
      )}
    </tr>
  );
};

PTableRowComponent.defaultProps = {
  includeActions: false,
};

export default PTableRowComponent;
