import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { getUniqueId } from "./random";
import { GlobalContext, StateUpdate } from "./context";
import {
  DEFAULT_TRANSITION_ENTER_DURATION,
  DEFAULT_TRANSITION_LEAVE_DURATION,
  DEFAULT_TRANSITION_PADDING,
} from "./contants";

export const useUniqueId = (): string => useMemo(() => getUniqueId(), []);

export const useBusyWatcher = (): [
  boolean,
  <Type>(p: Promise<Type>) => Promise<Type>,
] => {
  // One thing that's tricky here is that requests are often made back to back, which will
  // result in the busy state being set to true and then immediately set to false. To avoid
  // this, we immediately set busy state to true when we observe an outbound request, but we
  // debounce the busy state to false so that it only goes back to false after a short delay.

  const busyRef = useRef<boolean>(false);
  const [state, dispatch] = useContext(GlobalContext);
  const [isBusy, setIsBusy] = useState<boolean>(busyRef.current);

  useEffect(() => {
    busyRef.current = state.busy;
    if (state.busy && !isBusy) {
      setIsBusy(true);
    }
    if (!state.busy && isBusy) {
      setTimeout(() => {
        if (!busyRef.current) {
          setIsBusy(false);
        }
      }, 100);
    }
  }, [state.busy]);

  const promiseWrapper = async <Type>(
    promise: Promise<Type>,
  ): Promise<Type> => {
    dispatch(StateUpdate.INCR_BUSY_COUNTER);
    try {
      return await promise;
    } finally {
      dispatch(StateUpdate.DECR_BUSY_COUNTER);
    }
  };

  return [isBusy, promiseWrapper];
};

export const useSize = <T extends HTMLElement>(
  target: RefObject<T>,
): DOMRect | null => {
  const [size, setSize] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (target && target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export const useActivePageTime = (
  submitTime: ((timeInSeconds: number) => void) | null = null,
) => {
  const accumulatedTime = useRef<number>(0); // Accumulated active time in milliseconds
  const activeTimestamp = useRef<number | null>(null); // Timestamp of the last time the page became active

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        activeTimestamp.current = Date.now();
      } else if (activeTimestamp.current !== null) {
        const timeSpent = Date.now() - activeTimestamp.current;
        accumulatedTime.current += timeSpent;
        activeTimestamp.current = null;
        if (submitTime !== null) {
          submitTime(accumulatedTime.current / 1000);
        }
      }
    };

    const onBeforeUnload = () => {
      if (submitTime !== null) {
        if (activeTimestamp.current !== null) {
          const timeSpent = Date.now() - activeTimestamp.current;
          accumulatedTime.current += timeSpent;
        }
        submitTime(accumulatedTime.current / 1000);
      }
    };

    if (document.visibilityState === "visible") {
      activeTimestamp.current = Date.now();
    }

    document.addEventListener("visibilitychange", onVisibilityChange);
    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      if (activeTimestamp.current !== null) {
        const timeSpent = Date.now() - activeTimestamp.current;
        accumulatedTime.current += timeSpent;
      }
      document.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  // Return a function to get the current total active time
  return useCallback((): number => {
    let totalActiveTime = accumulatedTime.current;
    if (activeTimestamp.current !== null) {
      // Add time from the current active session if the page is active
      totalActiveTime += Date.now() - activeTimestamp.current;
    }
    return totalActiveTime / 1000;
  }, []);
};

export const useBackTimer = (
  stage: any,
  durationMs = DEFAULT_TRANSITION_ENTER_DURATION +
    DEFAULT_TRANSITION_LEAVE_DURATION +
    DEFAULT_TRANSITION_PADDING,
): [boolean, (fn?: () => any) => () => any] => {
  const [back, setBack] = useState<boolean>(false);

  const asBack =
    (fn?: () => any): (() => any) =>
    () => {
      setBack(true);
      if (fn) {
        fn();
      }
    };

  useEffect(() => {
    setTimeout(() => {
      if (back) {
        setBack(false);
      }
    }, durationMs);
  }, [stage]);

  return [back, asBack];
};
