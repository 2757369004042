import React from "react";
import { components } from "../../../../api/spec";
import PromptSummaryRow from "./PromptSummaryRow";
import PSkeletonList from "../../../ui/PSkeletonList";

type PromptSummaryListProps = {
  prompts: components["schemas"]["LLMPromptSummary"][];
  loading: boolean;
  onClick?:
    | ((prompt: components["schemas"]["LLMPromptSummary"]) => void)
    | null;
  disabled?: boolean;
};

const PromptSummaryListComponent = (props: PromptSummaryListProps) => {
  const { prompts, loading, onClick, disabled } = props;

  return (
    <div className="flex flex-col gap-2">
      {loading ? (
        <PSkeletonList count={10} gap={3} className="h-12 rounded-lg" />
      ) : null}
      {!loading && prompts.length === 0 ? <div>No prompts found</div> : null}
      {!loading &&
        prompts.length > 0 &&
        prompts.map((prompt) => (
          <PromptSummaryRow
            key={prompt.guid}
            prompt={prompt}
            onClick={onClick ? () => onClick(prompt) : null}
            disabled={disabled}
          />
        ))}
    </div>
  );
};

PromptSummaryListComponent.defaultProps = {
  onClick: null,
  disabled: false,
};

export default PromptSummaryListComponent;
