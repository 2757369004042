import React from "react";
import PEditTextScreen from "../../../ui/screens/PEditTextScreen";

type QuizGoalScreenProps = {
  onNextClicked: (goal: string) => void;
  onBackClicked: () => void;
  errors: string[];
  content?: string | null;
};

const QuizGoalScreenComponent = (props: QuizGoalScreenProps) => {
  const { onNextClicked, content, onBackClicked, errors } = props;

  return (
    <PEditTextScreen
      title="What are you looking to accomplish with these insights?"
      tooltip="The 'goal' of the quiz is the ideal outcome you're looking to achieve by getting insights from your audience. This could be a specific action you want to take, a decision you want to make, or a problem you want to solve."
      onNextClicked={onNextClicked}
      errors={errors}
      onBackClicked={onBackClicked}
      label="goal"
      placeholder={
        "ex: Identify ways to improve customer retention\nex: Ensure that the whole family feels taken care of on our next trip\nex: Throw an bigger, better, and safer block party next year"
      }
      content={content}
      required
      long
    />
  );
};

QuizGoalScreenComponent.defaultProps = {
  content: null,
};

export default QuizGoalScreenComponent;
