import React, { useEffect, useMemo, useState } from "react";
import { components, operations } from "../../../../api/spec";
import PErrors from "../../../ui/input/PErrors";
import InvocationsTable from "./InvocationsTable";
import { PFilterBadgesProps } from "../../../ui/PFilterBadges";
import { PFilterBadgeProps } from "../../../ui/PFilterBadge";
import { snakeCaseToTitleCase } from "../../../../util/strings";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";

type ListInvocationsScreenProps = {
  errors: string[];
  invocations:
    | components["schemas"]["ListLLMInvocationsResponseSerializerWrapper"]
    | null;
  disabled: boolean;
  onBackButtonClicked?: (() => void) | null;
  onNextButtonClicked?: (() => void) | null;
  query?:
    | operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
    | null;
  onAddFilterClicked?:
    | ((
        field: keyof NonNullable<
          operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
        >,
        value: any,
      ) => void)
    | null;
  onClearFilterClicked?:
    | ((
        field: keyof NonNullable<
          operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
        >,
      ) => void)
    | null;
  onSearchUpdated?: ((search: string | null) => void) | null;
};

const ListInvocationsScreenComponent = (props: ListInvocationsScreenProps) => {
  const {
    errors,
    invocations,
    disabled,
    onBackButtonClicked,
    onNextButtonClicked,
    query,
    onAddFilterClicked,
    onClearFilterClicked,
    onSearchUpdated,
  } = props;

  const [filterLabelMap, setFilterLabelMap] = useState<Record<string, string>>(
    {},
  );

  const visualFilterFields: (keyof NonNullable<
    operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
  >)[] = ["llm_model", "llm_prompt", "llm_provider"];

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  const offset = query?.offset ?? 0;
  const limit = query?.limit ?? DEFAULT_PAGE_SIZE;
  const hasBackButton = offset > 0;
  const hasNextButton =
    invocations !== null && invocations.content.count > offset + limit;

  const keyFromFilterField = (
    field: keyof NonNullable<
      operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
    >,
    value: any,
  ): string => `${field}-${value}`;

  const getFilterProps = (): PFilterBadgesProps | null => {
    if (query === null || query === undefined) {
      return null;
    }
    const badges: PFilterBadgeProps[] = [];
    visualFilterFields.forEach((field) => {
      const fieldValue = query[field];
      if (fieldValue !== undefined) {
        const labelKey = keyFromFilterField(field, fieldValue);
        badges.push({
          field: snakeCaseToTitleCase(field),
          value: filterLabelMap[labelKey] ?? fieldValue,
          onCancelClicked: onClearFilterClicked
            ? () => onClearFilterClicked(field)
            : null,
          disabled,
        });
      }
    });
    if (badges.length === 0) {
      return null;
    }
    return {
      badges,
    };
  };

  const onFilterFieldClicked = (
    field: keyof NonNullable<
      operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"]
    >,
    value: string,
    label: string,
  ) => {
    const filterKey = keyFromFilterField(field, value);
    const newLabelMap = { ...filterLabelMap, [filterKey]: label };
    setFilterLabelMap(newLabelMap);
    if (onAddFilterClicked) {
      onAddFilterClicked(field, value);
    }
  };

  const populateLabelsFromInvocations = () => {
    if (invocations === null) {
      return;
    }
    const newFilterLabelMap: Record<string, string> = { ...filterLabelMap };
    let updated = false;
    invocations.content.invocations.forEach((invocation) => {
      if (invocation.llm_prompt) {
        const promptKey = keyFromFilterField(
          "llm_prompt",
          invocation.llm_prompt,
        );
        const fieldValue = newFilterLabelMap[promptKey];
        if (invocation.llm_prompt_name !== fieldValue) {
          newFilterLabelMap[promptKey] = invocation.llm_prompt_name!;
          updated = true;
        }
      }
      if (invocation.llm_model) {
        const modelKey = keyFromFilterField("llm_model", invocation.llm_model);
        const fieldValue = newFilterLabelMap[modelKey];
        if (invocation.llm_model_name !== fieldValue) {
          newFilterLabelMap[modelKey] = invocation.llm_model_name!;
          updated = true;
        }
      }
      if (invocation.llm_provider) {
        const providerKey = keyFromFilterField(
          "llm_provider",
          invocation.llm_provider,
        );
        const fieldValue = newFilterLabelMap[providerKey];
        if (invocation.llm_provider_name !== fieldValue) {
          newFilterLabelMap[providerKey] = invocation.llm_provider_name!;
          updated = true;
        }
      }
    });
    if (updated) {
      setFilterLabelMap(newFilterLabelMap);
    }
  };

  const filterProps = useMemo(
    () => getFilterProps(),
    [query, filterLabelMap, disabled],
  );

  useEffect(() => {
    populateLabelsFromInvocations();
  }, [invocations]);

  return (
    <InvocationsTable
      invocations={invocations?.content.invocations ?? []}
      disabled={disabled}
      loading={invocations === null}
      pagination={{
        startIndex: offset,
        pageSize: limit,
        totalCount: invocations?.content.count ?? 0,
        disabled,
        onBackClicked: hasBackButton ? onBackButtonClicked : null,
        onNextClicked: hasNextButton ? onNextButtonClicked : null,
      }}
      filters={filterProps}
      onFilterFieldClicked={onAddFilterClicked ? onFilterFieldClicked : null}
      searchTerm={query?.search ?? null}
      onSearchUpdated={onSearchUpdated}
    />
  );
};

ListInvocationsScreenComponent.defaultProps = {
  onBackButtonClicked: null,
  onNextButtonClicked: null,
  query: null,
  onAddFilterClicked: null,
  onClearFilterClicked: null,
  onSearchUpdated: null,
};

export default ListInvocationsScreenComponent;
