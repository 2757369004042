import React from "react";
import PNavbarScreen from "../../ui/layout/PNavbarScreen";
import { PScreenShellProps } from "../../ui/layout/common";

const LandingScreenComponent = (props: PScreenShellProps) => {
  const { navbar } = props;

  return (
    <PNavbarScreen navbar={navbar}>
      <h1>Landing Page</h1>
    </PNavbarScreen>
  );
};

export default LandingScreenComponent;
