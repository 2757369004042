import { components, operations } from "../spec";
import { fetchAndUnpackError, useWrappersFetcher, Wrapper } from "../connector";

export const listLLMPrompts = async (
  request: operations["aiproxy_api_prompts_list_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ListLLMPromptsResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/aiproxy/api/prompts/list")
      .method("get")
      .create()(request ?? {}),
  );

export const getLLMPrompt = async (
  request: operations["aiproxy_api_prompts_get_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["LLMPromptDetailSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/aiproxy/api/prompts/get")
      .method("get")
      .create()(request),
  );

export const listLLMInvocations = async (
  request: operations["aiproxy_api_prompts_list_invocations_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ListLLMInvocationsResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/aiproxy/api/prompts/list-invocations")
      .method("get")
      .create()(request ?? {}),
  );

export const getLLMInvocation = async (
  request: operations["aiproxy_api_prompts_get_invocation_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["LLMInvocationDetailSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/aiproxy/api/prompts/get-invocation")
      .method("get")
      .create()(request),
  );
