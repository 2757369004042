import React, { useEffect, useState } from "react";
import { PScreenShellProps } from "../../../ui/layout/common";
import PSidebarScreen from "../../../ui/layout/PSidebarScreen";
import { components, operations } from "../../../../api/spec";
import { listLLMPrompts } from "../../../../api/aiproxy/prompt";
import { useBusyWatcher } from "../../../../util/hooks";
import ListPromptsScreen from "./ListPromptsScreen";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";
import { parseQueryString, updateQueryString } from "../../../../util/query";
import { PBreadcrumbsProps } from "../../../ui/PBreadcrumbs";

type ListPromptsPageProps = PScreenShellProps;

const ListPromptsPageComponent = (props: ListPromptsPageProps) => {
  const { navbar } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [listQuery, setListQuery] = useState<
    operations["aiproxy_api_prompts_list_retrieve"]["parameters"]["query"]
  >({});
  const [prompts, setPrompts] = useState<
    components["schemas"]["ListLLMPromptsResponseSerializerWrapper"] | null
  >(null);
  const [busy, busyWatcher] = useBusyWatcher();

  const fetchPrompts = async (
    query: operations["aiproxy_api_prompts_list_retrieve"]["parameters"]["query"],
  ) => {
    const [newPrompts, newErrors] = await listLLMPrompts(query, [busyWatcher]);
    if (newErrors !== null) {
      setErrors([newErrors!.message!]);
      return;
    }
    setListQuery(query);
    setPrompts(newPrompts!);
  };

  const onSearchTermUpdated = async (searchTerm: string | null) => {
    let existingSearchTerm = null;
    if (listQuery !== undefined && listQuery.search !== undefined) {
      existingSearchTerm = listQuery.search;
    }
    if (existingSearchTerm === searchTerm) {
      return;
    }
    const query = { ...listQuery, offset: 0, search: searchTerm ?? undefined };
    await fetchPrompts(query);
  };

  const getCurrentOffsetLimit = () => {
    let offset = 0;
    let pageSize: number = DEFAULT_PAGE_SIZE;
    if (listQuery !== undefined) {
      if (listQuery.limit !== undefined) {
        pageSize = listQuery.limit;
      }
      if (listQuery.offset !== undefined) {
        offset = listQuery.offset;
      }
    }
    return { offset, pageSize };
  };

  const { offset, pageSize } = getCurrentOffsetLimit();

  const onBackButtonClicked = async () => {
    const newOffset = Math.max(0, offset - pageSize);
    const query = { ...listQuery, offset: newOffset };
    await fetchPrompts(query);
  };

  const onNextButtonClicked = async () => {
    const newOffset = offset + pageSize;
    const query = { ...listQuery, offset: newOffset };
    await fetchPrompts(query);
  };

  const getCurrentQueryContents = () => {
    const template: operations["aiproxy_api_prompts_list_retrieve"]["parameters"]["query"] =
      {
        default_model_config: "",
        description: "",
        limit: -1,
        name: "",
        offset: -1,
        search: "",
      };
    return parseQueryString(template);
  };

  useEffect(() => {
    fetchPrompts(getCurrentQueryContents());
  }, []);

  useEffect(() => {
    updateQueryString(listQuery ?? {});
  }, [listQuery]);

  const getBreadrumbs = (): PBreadcrumbsProps => ({
    homeUrl: "/aiproxy/",
    entries: [
      {
        name: "Prompts",
      },
    ],
  });

  return (
    <PSidebarScreen navbar={navbar} breadcrumbs={getBreadrumbs()}>
      <ListPromptsScreen
        errors={errors}
        prompts={prompts}
        disabled={busy}
        offset={offset}
        limit={pageSize}
        onBackButtonClicked={onBackButtonClicked}
        onNextButtonClicked={onNextButtonClicked}
        onSearchUpdated={onSearchTermUpdated}
        searchTerm={listQuery?.search ?? null}
      />
    </PSidebarScreen>
  );
};

export default ListPromptsPageComponent;
