import React from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";

type ReportExecutiveSummaryCardProps = {
  report: components["schemas"]["GetQuizReportResponseSerializerWrapper"];
};

const ReportExecutiveSummaryCardComponent = (
  props: ReportExecutiveSummaryCardProps,
) => {
  const { report } = props;

  return (
    <PCard>
      <div className="flex flex-col gap-3">
        <PTitle className="whitespace-pre" title="Executive Summary" />
        <div className="text-p-black-lighter">
          {report.content.report.executive_summary}
        </div>
      </div>
    </PCard>
  );
};

export default ReportExecutiveSummaryCardComponent;
