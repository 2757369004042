import React, { ReactNode } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import PButton, { PButtonProps } from "./PButton";

type PSendButtonProps = PButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const PSendButtonComponent = (props: PSendButtonProps) => {
  const { text, ...rest } = props;

  return <PButton {...rest}>{text}</PButton>;
};

PSendButtonComponent.defaultProps = {
  text: "send",
  icon: <PaperAirplaneIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default PSendButtonComponent;
