import React from "react";
import PQuestionsFormScreen, {
  QuestionAndAnswer,
} from "../../../ui/screens/PQuestionsFormScreen";
import { components } from "../../../../api/spec";

type QuizClarificationsScreenProps = {
  questions: components["schemas"]["QuestionWithPlaceholder"][];
  onNextClicked: (answers: QuestionAndAnswer[]) => void;
  onSkipClicked?: (() => void) | null;
};

const QuizClarificationScreenComponent = (
  props: QuizClarificationsScreenProps,
) => {
  const { questions, onNextClicked, onSkipClicked } = props;

  return (
    <PQuestionsFormScreen
      title="A few more details... (OPTIONAL)"
      tooltip="These are a few clarifying questions that we think would be quite helpful to deepen our understanding of your needs before we generate a quiz for you. These are all optional, but highly encouraged!"
      questions={questions}
      onNextClicked={onNextClicked}
      onSkipClicked={onSkipClicked}
    />
  );
};

QuizClarificationScreenComponent.defaultProps = {
  onSkipClicked: null,
};

export default QuizClarificationScreenComponent;
