import React, { PropsWithChildren, ReactNode } from "react";
import PCenterColumnScreen from "./PCenterColumnScreen";
import { classNames } from "../../../util/strings";

type PCenterColumnStickyButtonScreenProps = {
  className?: string | null;
  fullWidth?: boolean;
  buttons?: ReactNode[];
  stacked?: boolean;
};

const PCenterColumnStickyButtonScreenComponent = (
  props: PropsWithChildren<PCenterColumnStickyButtonScreenProps>,
) => {
  const { className, fullWidth, buttons, stacked, children } = props;

  const getClassName = (): string => {
    if (stacked) {
      return "flex flex-col gap-1";
    }
    if (buttons!.length <= 3) {
      return "flex flex-row-reverse gap-3 sm:items-end";
    }
    return "flex flex-col gap-1";
  };

  return (
    <PCenterColumnScreen
      className={className}
      fullWidth={fullWidth}
      stickyFooterContent={
        buttons && buttons.length > 0 ? (
          <div className={classNames(getClassName())}>{buttons}</div>
        ) : null
      }
    >
      {children}
    </PCenterColumnScreen>
  );
};

PCenterColumnStickyButtonScreenComponent.defaultProps = {
  className: null,
  fullWidth: false,
  stacked: false,
  buttons: [],
};

export default PCenterColumnStickyButtonScreenComponent;
