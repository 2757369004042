import { components } from "../../../../api/spec";

// eslint-disable-next-line import/prefer-default-export
export const bgColorFromImportance = (
  importance: components["schemas"]["ImportanceEnum"],
  lighten = false,
): string => {
  switch (importance) {
    case "critical":
      if (lighten) {
        return "bg-success/20 text-p-black";
      }
      return "bg-success text-p-black";
    case "high":
      if (lighten) {
        return "bg-danger/20 text-p-black";
      }
      return "bg-danger text-p-white";
    case "medium":
      if (lighten) {
        return "bg-warning/20 text-p-black";
      }
      return "bg-warning text-p-white";
    case "low":
      if (lighten) {
        return "bg-primary/20 text-p-black";
      }
      return "bg-primary text-p-white";
    default:
      throw new Error(`Unknown importance: ${importance}`);
  }
};
