import React, { ReactNode, useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import PInput from "./PInput";
import { classNames } from "../../../util/strings";

type PSearchBoxProps = React.ComponentPropsWithoutRef<"input"> & {
  searchTerm?: string | null;
  onEnterPressed?: (() => void) | null;
  onSearchTermUpdated: (newTerm: string | null) => void;
  errors?: string[] | null;
  label?: string | null;
  placeholder?: string | null;
  below?: ReactNode;
};

const PSearchBoxComponent = (props: PSearchBoxProps) => {
  const {
    searchTerm: searchTermProp,
    onSearchTermUpdated: onSearchTermUpdatedInput,
    onEnterPressed,
    errors,
    className,
    label,
    placeholder,
    below,
    ...rest
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>(searchTermProp || "");
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const onSearchTermUpdated = (newSearchTerm: string) => {
    if (searchTerm === "") {
      onSearchTermUpdatedInput(null);
    } else {
      onSearchTermUpdatedInput(newSearchTerm);
    }
  };

  useEffect(() => {
    onSearchTermUpdated(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchTermProp === null || searchTermProp === undefined) {
      return;
    }
    if (searchTermProp === searchTerm) {
      return;
    }
    setSearchTerm(searchTermProp);
  }, [searchTermProp]);

  return (
    <div className="relative">
      <PInput
        errors={errors || []}
        onEnterPressed={onEnterPressed}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        type="search"
        className={classNames("w-full max-w-lg lg:max-w-xs", className)}
        icon={<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />}
        placeholder={placeholder}
        label={label}
        {...rest}
      />
      {below && <div className="absolute left-0 z-20">{below}</div>}
    </div>
  );
};

PSearchBoxComponent.defaultProps = {
  searchTerm: null,
  onEnterPressed: null,
  errors: null,
  placeholder: "search",
  label: null,
  below: null,
};

export default PSearchBoxComponent;
