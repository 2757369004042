import React, { ReactNode } from "react";
import { components } from "../../../../api/spec";
import PDescriptionList, {
  headerLink,
  ListEntry,
} from "../../../ui/PDescriptionList";
import { prettyPrintJson } from "../../../../util/strings";
import PSkeleton from "../../../ui/PSkeleton";
import PCode from "../../../ui/PCode";
import PJson from "../../../ui/PJson";

type ViewPromptScreenProps = {
  prompt: components["schemas"]["LLMPromptDetailSerializerWrapper"] | null;
};

const ViewPromptScreenComponent = (props: ViewPromptScreenProps) => {
  const { prompt } = props;

  const valueOrSkeleton = (
    propName: keyof components["schemas"]["LLMPromptDetail"],
    skeletonClassName: string,
  ): ReactNode => {
    if (prompt === null) {
      return <PSkeleton className={skeletonClassName} />;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return prompt.content[propName];
  };

  const codeOrSkeleton = (
    propName: keyof components["schemas"]["LLMPromptDetail"],
    fromJsonString: boolean,
    codeType: string,
    skeletonClassName: string,
  ): ReactNode => {
    if (prompt === null) {
      return <PSkeleton className={skeletonClassName} />;
    }
    const value = prompt.content[propName];
    if (!value) {
      return <PCode language="text" />;
    }
    let content: string;
    if (fromJsonString) {
      content = prettyPrintJson(value as string);
    } else {
      content = value as string;
    }
    if (codeType === "json") {
      return <PJson content={content} />;
    }
    return <PCode content={content} language={codeType} />;
  };

  const getEntries = (): ListEntry[] => [
    {
      key: "Name",
      value: valueOrSkeleton("name", "min-w-64 h-4"),
    },
    {
      key: "Description",
      value: valueOrSkeleton("description", "min-w-64 h-12"),
    },
    {
      key: "GUID",
      value: valueOrSkeleton("guid", "min-w-64 h-4"),
    },
    {
      key: "System Message",
      value: valueOrSkeleton("system_message", "min-w-64 h-12"),
    },
    {
      key: "Input Schema",
      value: codeOrSkeleton("input_schema", true, "json", "min-w-64 h-36"),
    },
    {
      key: "Output Schema",
      value: codeOrSkeleton("output_schema", true, "json", "min-w-64 h-36"),
    },
    {
      key: "Template Content",
      value: codeOrSkeleton("template_content", false, "text", "min-w-64 h-36"),
    },
  ];

  const getHeaderLinks = (): headerLink[] => {
    if (prompt === null) {
      return [];
    }
    return [
      {
        title: "view invocations",
        url: prompt.content.invocations_url,
      },
    ];
  };

  return (
    <PDescriptionList
      title={prompt === null ? "Loading..." : prompt.content.name}
      headerLinks={getHeaderLinks()}
      entries={getEntries()}
    />
  );
};

export default ViewPromptScreenComponent;
