import React, { useEffect, useState } from "react";
import { components } from "../../../../api/spec";
import { useBusyWatcher } from "../../../../util/hooks";
import {
  getQuizReportSharingStatus,
  setQuizReportSharingStatus,
} from "../../../../api/reflection/reports";
import ReportSharingDialogScreen from "./ReportSharingDialogScreen";

export type ReportSharingDialogProps = {
  quizGuid: string;
};

type innerProps = ReportSharingDialogProps & {
  onClose?: (() => void) | null;
};

const ReportSharingDialogComponent = (props: innerProps) => {
  const { quizGuid, onClose } = props;

  const [quizSharingStatus, setQuizSharingStatus] = useState<
    | components["schemas"]["GetReportSharingStatusResponseSerializerWrapper"]
    | null
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const fetchSharingStatus = async () => {
    const [fetchedStatus, newErrors] = await getQuizReportSharingStatus(
      {
        quiz: quizGuid,
      },
      [busyWatcher],
    );
    if (newErrors !== null) {
      setErrors([newErrors.message!]);
      return;
    }
    setQuizSharingStatus(fetchedStatus);
  };

  const onToggleClicked = async (newSharingStatus: boolean) => {
    const [__, newErrors] = await setQuizReportSharingStatus(
      {
        quiz: quizGuid,
        is_shared: newSharingStatus,
      },
      [busyWatcher],
    );
    if (newErrors !== null) {
      setErrors([newErrors.message!]);
      return;
    }
    await fetchSharingStatus();
  };

  useEffect(() => {
    fetchSharingStatus();
  }, []);

  return (
    <ReportSharingDialogScreen
      sharingStatus={quizSharingStatus}
      onToggleClicked={onToggleClicked}
      errors={errors}
      onClose={onClose}
    />
  );
};

ReportSharingDialogComponent.defaultProps = {
  onClose: null,
};

export default ReportSharingDialogComponent;
