import React, { useState } from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PInput from "../../../ui/input/PInput";
import PErrors from "../../../ui/input/PErrors";
import { isEmailValid } from "../../../../util/validation";

export type RegistrantInfo = {
  firstName: string;
  lastName: string;
  email: string;
};

type QuizRegistrationFormScreenProps = {
  onRegisterClicked: (registrant: RegistrantInfo) => void;
  errors: string[];
};

const QuizRegistrationFormScreenComponent = (
  props: QuizRegistrationFormScreenProps,
) => {
  const { onRegisterClicked: onRegisterClickedInput, errors } = props;

  const [firstName, setFirstName] = useState<string>("");
  const [firstNameTouched, setFirstNameTouched] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameTouched, setLastNameTouched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [busy, _] = useBusyWatcher();

  const onRegisterClicked = () => {
    onRegisterClickedInput({
      firstName,
      lastName,
      email,
    });
  };

  const getFirstNameErrors = (): string[] => {
    const innerErrors: string[] = [];
    if (firstNameTouched && firstName === "") {
      innerErrors.push("First name is required");
    }
    return innerErrors;
  };

  const getLastNameErrors = (): string[] => {
    const innerErrors: string[] = [];
    if (lastNameTouched && lastName === "") {
      innerErrors.push("Last name is required");
    }
    return innerErrors;
  };

  const getEmailErrors = (): string[] => {
    const innerErrors: string[] = [];
    if (emailTouched && email === "") {
      innerErrors.push("Email address is required");
    }
    if (emailTouched && email.length > 0 && !isEmailValid(email)) {
      innerErrors.push("Not a valid email address.");
    }
    return innerErrors;
  };

  const canSubmit = (): boolean =>
    firstName.length > 0 &&
    lastName.length > 0 &&
    email.length > 0 &&
    isEmailValid(email);

  return (
    <PFormScreen
      title="Register for Quiz"
      comment="we need a few details about you to get the quiz set up for you. we won't use any of this information to contact you."
      nextDisabled={busy || !canSubmit()}
      onNextClicked={onRegisterClicked}
    >
      <PErrors errors={errors} />
      <PInput
        type="text"
        label="First name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        onEnterPressed={() => canSubmit() && onRegisterClicked()}
        onBlur={() => setFirstNameTouched(true)}
        errors={getFirstNameErrors()}
        required
      />
      <PInput
        type="text"
        label="Last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        onEnterPressed={() => canSubmit() && onRegisterClicked()}
        onBlur={() => setLastNameTouched(true)}
        errors={getLastNameErrors()}
        required
      />
      <PInput
        type="email"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onEnterPressed={() => canSubmit() && onRegisterClicked()}
        onBlur={() => setEmailTouched(true)}
        errors={getEmailErrors()}
        required
      />
    </PFormScreen>
  );
};

export default QuizRegistrationFormScreenComponent;
