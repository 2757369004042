import React from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import PDataRow from "../../../ui/PDataRow";
import { formatDuration } from "../../../../util/duration";

type ReportAnalyticsCardProps = {
  report: components["schemas"]["GetQuizReportResponseSerializerWrapper"];
};

const ReportAnalyticsCardComponent = (props: ReportAnalyticsCardProps) => {
  const { report } = props;

  return (
    <PCard>
      <div className="flex flex-col gap-3">
        <PTitle className="whitespace-pre" title="📊 Analytics" />
        <div className="w-full">
          <PDataRow
            stats={[
              {
                name: "time to create",
                value: formatDuration(
                  report.content.report.analytics.creation_duration_s,
                ),
              },
              {
                name: "# of participants",
                value: report.content.report.participants
                  .filter((p) => p.is_completed)
                  .length.toString(),
              },
              {
                name: "participant time spent",
                value: formatDuration(
                  report.content.report.analytics.participation_duration_s,
                ),
              },
              {
                name: "unique insights shared",
                value:
                  report.content.report.analytics.unique_insights_count.toString(),
              },
            ]}
          />
        </div>
      </div>
    </PCard>
  );
};

export default ReportAnalyticsCardComponent;
