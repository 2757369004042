import React, { ReactNode, useRef, useState } from "react";
import { components } from "../../../../api/spec";
import PBaseDialog from "../../../ui/dialogs/PBaseDialog";
import MultipleChoiceBody from "./MultipleChoiceBody";
import TextBody from "./TextBody";
import OpinionScaleBody from "./OpinionScaleBody";
import PButton from "../../../ui/buttons/PButton";

export type ReportSummaryDialogProps = {
  question: components["schemas"]["CommonQuizQuestionContent"];
};

type innerProps = ReportSummaryDialogProps & {
  onClose?: (() => void) | null;
};

const ReportSummaryDialogComponent = (props: innerProps) => {
  const { question, onClose } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const getBody = (): ReactNode => {
    switch (question.question.question_type) {
      case "multiple_choice":
        return (
          <MultipleChoiceBody content={question.multiple_choice_summary!} />
        );
      case "long_text":
        return <TextBody content={question.text_summary!} />;
      case "short_text":
        return <TextBody content={question.text_summary!} />;
      case "opinion_scale":
        return <OpinionScaleBody content={question.opinion_scale_summary!} />;
      default:
        return (
          <pre className="text-xs">
            {JSON.stringify(question.question, null, 2)}
          </pre>
        );
    }
  };

  const close = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const closeButtonRef = useRef(null);

  return (
    <PBaseDialog title={question.question.title} show={isOpen} onClose={close}>
      <div className="flex flex-col gap-3">
        <div>{question.question.description}</div>
        <div className="flex flex-col gap-3 text-left">{getBody()}</div>
        <div className="flex flex-row w-full gap-4 justify-end">
          <PButton
            kind="primary"
            className="min-w-24"
            onClick={close}
            ref={closeButtonRef}
          >
            close
          </PButton>
        </div>
      </div>
    </PBaseDialog>
  );
};

ReportSummaryDialogComponent.defaultProps = {
  onClose: null,
};

export default ReportSummaryDialogComponent;
