import React from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PTitle from "../../../ui/PTitle";

type QuizWelcomeScreenProps = {
  recipientName: string;
  quizTitle: string;
  quizDescription: string;
  timeEstimate: string;
  onNextClicked: () => void;
};

const QuizWelcomeScreenComponent = (props: QuizWelcomeScreenProps) => {
  const {
    recipientName,
    quizTitle,
    quizDescription,
    timeEstimate,
    onNextClicked,
  } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <PFormScreen
      title={`Welcome ${recipientName}`}
      nextText="Start Quiz"
      onNextClicked={onNextClicked}
      nextDisabled={busy}
    >
      <div className="flex flex-col gap-3">
        <PTitle title={quizTitle} />
        <p>{quizDescription}</p>
      </div>
      <div>
        this quiz should take approximately{" "}
        <span className="font-semibold">{timeEstimate}</span>.
      </div>
    </PFormScreen>
  );
};

export default QuizWelcomeScreenComponent;
