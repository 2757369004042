import React from "react";
import { Widget } from "@typeform/embed-react";

type EmbedTypeformScreenProps = {
  typeformId: string;
  recipient: string;
  onFormCompleted?: (() => void) | null;
};

const EmbedTypeformScreenComponent = (props: EmbedTypeformScreenProps) => {
  const { typeformId, recipient, onFormCompleted } = props;

  return (
    <Widget
      id={typeformId}
      hidden={{
        recipient_guid: recipient,
      }}
      className="h-screen w-screen"
      onSubmit={onFormCompleted ?? undefined}
    />
  );
};

EmbedTypeformScreenComponent.defaultProps = {
  onFormCompleted: null,
};

export default EmbedTypeformScreenComponent;
