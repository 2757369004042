import React from "react";
import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import { classNames } from "../../util/strings";

const PSkeletonComponents = (props: SkeletonProps) => {
  const { className } = props;

  const hasHeight = className?.includes("h-");

  return (
    <Skeleton
      className={classNames(
        "w-full p-0.5 mt-2",
        !hasHeight ? "h-64" : null,
        className,
      )}
      {...props}
    />
  );
};

PSkeletonComponents.defaultProps = {
  className: null,
};

export default PSkeletonComponents;
