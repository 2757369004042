import React from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { CommonWellButtonProps } from "./common";
import PIconButton from "./PIconButton";

const PIconTrashButtonComponent = (props: CommonWellButtonProps) => (
  <PIconButton {...props} icon={<TrashIcon />} />
);

PIconTrashButtonComponent.defaultProps = {
  disabled: false,
};

export default PIconTrashButtonComponent;
