import React from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";

type CreateIntroScreenProps = {
  onNextClicked: () => void;
};

const CreateIntroScreenComponent = (props: CreateIntroScreenProps) => {
  const { onNextClicked } = props;

  return (
    <PFormScreen onNextClicked={onNextClicked} title="Introduction">
      <div className="text-sm">
        To start creating your quiz, we're going to ask you for three key
        details (described below) on the following screens.
      </div>
      <div className="font-semibold text-sm mt-2">1. Core Question</div>
      <div className="text-sm">
        <div>What specific question do you want to explore?</div>
        <div className="italic text-xs mt-2 p-1 bg-primary-lightest/20 rounded-lg text-p-black-lighter">
          e.g., How could we improve our neighborhood block party?
        </div>
      </div>
      <div className="font-semibold text-sm mt-2">2. Audience</div>
      <div className="text-sm">
        <div>Who would you like to answer this question?</div>
        <div className="italic text-xs mt-2 p-1 bg-primary-lightest/20 rounded-lg text-p-black-lighter">
          e.g., My immediate neighbors
        </div>
      </div>
      <div className="font-semibold text-sm mt-2">3. Goal</div>
      <div className="text-sm">
        <div>What do you hope to achieve with these insights?</div>
        <div className="italic text-xs mt-2 p-1 bg-primary-lightest/20 rounded-lg text-p-black-lighter">
          e.g., Planning an even better, more inclusive event next year
        </div>
      </div>
      <div className="text-sm mt-2">
        The more detail you provide, the better we can tailor your quiz
        questions.
      </div>
    </PFormScreen>
  );
};

export default CreateIntroScreenComponent;
