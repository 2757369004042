import React from "react";
import { numberToFixedLocaleString } from "../../util/number";

type PDurationSecondsProps = {
  duration?: number | null;
};

const PDurationSecondsComponent = (props: PDurationSecondsProps) => {
  const { duration } = props;

  let content = "--";
  if (duration !== null && duration !== undefined) {
    content = `${numberToFixedLocaleString(duration, 2)}s`;
  }

  return <span>{content}</span>;
};

PDurationSecondsComponent.defaultProps = {
  duration: null,
};

export default PDurationSecondsComponent;
