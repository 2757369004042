import React, { PropsWithChildren } from "react";

const QuizScreenCellComponent = (props: PropsWithChildren<any>) => {
  const { children } = props;

  return (
    <div className="border-t border-p-black-lightest/10 px-4 py-6 sm:col-span-1 sm:px-0">
      {children}
    </div>
  );
};

export default QuizScreenCellComponent;
