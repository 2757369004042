import React, { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { classNames } from "../../../util/strings";
import PErrors from "./PErrors";
import { useUniqueId } from "../../../util/hooks";
import PLabel from "./PLabel";

export type radioOption = {
  value: string;
  label: string;
};

type PRadioButtonsProps = {
  options: radioOption[];
  selected?: number | null;
  onSelected: (value: radioOption) => void;
  label?: string | null;
  disabled?: boolean;
  errors?: string[];
  required?: boolean;
};

const PRadioButtonsComponent = (props: PRadioButtonsProps) => {
  const {
    options,
    selected: selectedInput,
    onSelected: onSelectedInput,
    label,
    disabled,
    errors,
    required,
  } = props;

  const labelId = useUniqueId();
  const [selected, setSelected] = useState<radioOption>(
    selectedInput ? options[selectedInput] : options[0],
  );

  const onSelected = (newSelected: radioOption) => {
    setSelected(newSelected);
    onSelectedInput(newSelected);
  };

  const inError = Boolean(errors && errors.length > 0);

  return (
    <>
      <fieldset aria-label={label ?? undefined}>
        {label ? (
          <PLabel
            label={label}
            htmlFor={labelId}
            required={required}
            error={inError}
          />
        ) : null}

        <RadioGroup
          value={selected}
          onChange={onSelected}
          className="mt-2 flex items-center gap-3 flex-wrap"
          id={labelId}
        >
          {options.map((option) => (
            <Radio
              key={option.value}
              value={option}
              disabled={disabled}
              className={classNames(
                disabled
                  ? "cursor-not-allowed opacity-25"
                  : "cursor-pointer focus:outline-none",
                "flex items-center justify-center rounded-md bg-p-white p-2",
                "text-sm font-semibold uppercase text-p-black-lighter ring-1 ring-p-black-lightest",
                "hover:bg-gray-50 data-[checked]:bg-primary data-[checked]:text-white",
                "data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2",
                "data-[focus]:ring-primary data-[focus]:ring-offset-2 data-[checked]:hover:bg-primary-lighter",
                "sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset whitespace-pre",
              )}
            >
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </fieldset>
      <PErrors className="mt-2" errors={errors} />
    </>
  );
};

PRadioButtonsComponent.defaultProps = {
  selected: null,
  label: null,
  disabled: false,
  errors: [],
  required: false,
};

export default PRadioButtonsComponent;
