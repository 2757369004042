import React, { ReactNode } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import PTitle from "../../../ui/PTitle";
import PCenterColumnStickyButtonScreen from "../../../ui/screens/PCenterColumnStickyButtonScreen";
import PButton from "../../../ui/buttons/PButton";

type QuizCompletionScreenProps = {
  recipientName: string;
  createQuizUrl: string;
};

const QuizCompletionScreenComponent = (props: QuizCompletionScreenProps) => {
  const { recipientName, createQuizUrl } = props;

  const getButtons = (): ReactNode[] => [
    <PButton
      key="create-button"
      href={createQuizUrl}
      kind="primary"
      className="w-full sm:w-auto"
      icon={<PlusIcon className="h-5 w-5" />}
    >
      create your own quiz
    </PButton>,
  ];

  return (
    <PCenterColumnStickyButtonScreen
      buttons={getButtons()}
      stacked={false}
      fullWidth
    >
      <PTitle title={`Thank you ${recipientName}!`} />
      <div className="h-6" />
      <div className="flex flex-col gap-3">
        <div>we appreciate you taking the time to respond.</div>
        <div>ready to create a quiz of your own?? click below!</div>
      </div>
    </PCenterColumnStickyButtonScreen>
  );
};

export default QuizCompletionScreenComponent;
