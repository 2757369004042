import React from "react";
import { LinkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { Recipient } from "../RecipientEntryRow";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PButton from "../../../ui/buttons/PButton";
import { getPathAtCurrentUrl } from "../../../../util/url";

type QuizCreationCompletedScreenProps = {
  quizGuid: string;
  title: string;
  description: string;
  recipients: Recipient[];
  shareableUrl: string;
  viewQuizUrl: string;
};

const QuizCreationCompletedScreenComponent = (
  props: QuizCreationCompletedScreenProps,
) => {
  const {
    quizGuid,
    title,
    description,
    recipients,
    shareableUrl,
    viewQuizUrl,
  } = props;

  const [busy, _] = useBusyWatcher();

  const onShareableLinkClicked = async () => {
    const shareableLink = `${shareableUrl}?quiz=${quizGuid}`;
    const fullUrl = getPathAtCurrentUrl(shareableLink);
    await navigator.clipboard.writeText(fullUrl);
    toast.success("Link copied to clipboard");
  };

  return (
    <PFormScreen
      title="Quiz Created"
      tooltip="Your quiz has been created and is ready to be shared / taken. If you added recipients, then we've went ahead and sent them emails with instructions on how they can participate."
      nextText="view quiz"
      nextDisabled={busy}
      onNextClicked={() => {
        window.location.href = `${viewQuizUrl}?quiz=${quizGuid}`;
      }}
    >
      <div className="bg-primary-lightest rounded-lg p-3">
        <div className="font-semibold mb-2">{title}</div>
        <div>{description}</div>
      </div>
      <div>
        <div className="font-semibold">Sent to:</div>
        {recipients.length > 0 ? (
          <ul className="list-disc ml-4">
            {recipients.map((recipient) => (
              <li key={recipient.emailAddress}>
                <div className="flex flex-row gap-2 items-center">
                  <div className="text-sm">
                    {recipient.firstName} {recipient.lastName}
                  </div>
                  <div className="text-xs italic">{recipient.emailAddress}</div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-sm italic">no recipients added</div>
        )}
      </div>

      <PButton
        icon={<LinkIcon className="w-5 h-5" />}
        className="mt-3"
        kind="primary"
        onClick={onShareableLinkClicked}
      >
        copy shareable link
      </PButton>
    </PFormScreen>
  );
};

export default QuizCreationCompletedScreenComponent;
