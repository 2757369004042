import React from "react";
import { toast } from "react-toastify";
import { LinkIcon } from "@heroicons/react/24/outline";
import { components } from "../../../../api/spec";
import { clickableText } from "../../../../util/style";
import { getPathAtCurrentUrl } from "../../../../util/url";
import { dateToYMD } from "../../../../util/date";
import PButton from "../../../ui/buttons/PButton";
import { classNames } from "../../../../util/strings";

type QuizSummaryRowProps = {
  quiz: components["schemas"]["ReflectionQuizSummary"];
  viewUrl: string;
  reportUrl: string;
  shareableUrl: string;
};

const QuizSummaryRowComponent = (props: QuizSummaryRowProps) => {
  const { quiz, viewUrl, reportUrl, shareableUrl } = props;

  const onShareableLinkClicked = async () => {
    const shareableLink = `${shareableUrl}?quiz=${quiz.guid}`;
    const fullUrl = getPathAtCurrentUrl(shareableLink);
    await navigator.clipboard.writeText(fullUrl);
    toast.success("Link copied to clipboard");
  };

  return (
    <div key={quiz.guid}>
      <div className="flex flex-col gap-2 sm:hidden">
        <div className="flex flex-col">
          <div>{quiz.title}</div>
          <div className="text-sm text-p-black-lightest">
            Created on {dateToYMD(new Date(quiz.time_created))}
          </div>
        </div>
        <div className="text-sm">
          {quiz.completed_recipient_count} / {quiz.recipient_count} responses
        </div>
        <div>
          {quiz.is_report_available ? (
            <a
              href={`${reportUrl}?quiz=${quiz.guid}`}
              className={clickableText}
            >
              access report
            </a>
          ) : (
            <span className="italic text-p-black-lightest">
              {quiz.is_completed ? "processing results..." : "in progress"}
            </span>
          )}
        </div>
        <div>
          <a
            href={`${viewUrl}?quiz=${quiz.guid}`}
            className={classNames(clickableText)}
          >
            <div className="w-full">view</div>
          </a>
        </div>
        {!quiz.is_completed ? (
          <div>
            <PButton
              icon={<LinkIcon className="w-5 h-5" />}
              kind="primary"
              onClick={onShareableLinkClicked}
            >
              copy link
            </PButton>
          </div>
        ) : null}
      </div>
      <div className="hidden grid-cols-5 sm:grid items-center justify-center">
        <div className="flex flex-col gap-2 col-span-2">
          <div>{quiz.title}</div>
          <div className="text-sm text-p-black-lightest">
            Created on {dateToYMD(new Date(quiz.time_created))}
          </div>
        </div>
        <div className="col-span-1">
          {quiz.completed_recipient_count} / {quiz.recipient_count} responses
        </div>
        <div className="col-span-1">
          {quiz.is_report_available ? (
            <a
              href={`${reportUrl}?quiz=${quiz.guid}`}
              className={clickableText}
            >
              access report
            </a>
          ) : (
            <span className="italic text-p-black-lightest">
              {quiz.is_completed ? "processing results..." : "in progress"}
            </span>
          )}
        </div>
        <div className="col-span-1 flex flex-row gap-4 justify-end items-center">
          {!quiz.is_completed ? (
            <PButton
              icon={<LinkIcon className="w-5 h-5" />}
              kind="primary"
              onClick={onShareableLinkClicked}
            >
              copy link
            </PButton>
          ) : null}
          <a href={`${viewUrl}?quiz=${quiz.guid}`} className={clickableText}>
            view
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuizSummaryRowComponent;
