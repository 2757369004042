// eslint-disable-next-line import/prefer-default-export
export const getPathAtCurrentUrl = (path: string): string => {
  const url = new URL(window.location.href);
  let fullPath: string;
  if (!path.startsWith("/")) {
    fullPath = `/${path}`;
  } else {
    fullPath = path;
  }
  return url.origin + fullPath;
};
